import { useMutation, useQuery } from '@tanstack/react-query';
import { trimEnd } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { fetchGet, fetchPost, LayoutContext, newGuid } from 'wcz-layout';
import { DepartmentContext } from '../../contexts/DepartmentContext';
import Category from '../../models/Category';
import Template from '../../models/Template';
import { pmtcUrl } from '../../utils/BaseUrl';
import TemplateDialog from './common/TemplateDialog';

interface AddTemplateProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    refetch: () => void
}

export default function AddTemplate(props: AddTemplateProps) {
    const { open, setOpen, refetch } = props;
    const [template, setTemplate] = useState({} as Template);
    const { user, snackbar, t } = useContext(LayoutContext);
    const { department } = useContext(DepartmentContext);

    const { data: categories = [] } = useQuery<Category[]>(["categories"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/category${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!open,
    });
    
    // eslint-disable-next-line
    useEffect(() => initTemplate(), [open]);

    const initTemplate = () => {
        setTemplate({
            ...template,
            isActive: true,
            department: user.department,
            tasks: [{ id: newGuid(), text: "" }]
        });
    };

    const { mutate: create } = useMutation((data: Template) => fetchPost(pmtcUrl + "/v1/template", data), {
        onSuccess: () => {
            snackbar({ message: t("Created"), severity: "success" });
            setOpen(false);
            refetch();
        },
    });

    const onSubmit = () => {
        create({
            ...template,
            tasks: template.tasks.map((task, index) => ({ ...task, position: index }))
        });
    };

    return <TemplateDialog open={open} onClose={() => setOpen(false)} template={template} setTemplate={setTemplate} onSubmit={onSubmit} categories={categories} />;
}