import { CheckBox, CheckBoxOutlineBlank, Close } from "@mui/icons-material";
import { Box, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { fetchGet } from "wcz-layout";
import { TaskResult } from "../../models/enums/TaskResult";
import Maintenance from "../../models/Maintenance";
import { Shutdown } from "../../models/Shutdown";
import { pmtcUrl } from "../../utils/BaseUrl";

interface DetailPanelProps {
    params: GridRowParams
}

export default function DetailPanel(props: DetailPanelProps) {
    const { params } = props;

    const { data: maintenance = {} as Shutdown } = useQuery<Maintenance>(["shutdown", params.id], ({ signal }) => fetchGet(pmtcUrl + "/v1/shutdown/" + params.id, signal), {
        enabled: !!params.id
    });

    return (
        <Box sx={{ overflow: 'auto', height: 200 }}>
            <List dense>
                {maintenance.tasks?.map(task => (
                    <React.Fragment key={task.id}>
                        <ListItem>
                            <ListItemAvatar>
                                {task.result === TaskResult.NA && <CheckBoxOutlineBlank />}
                                {task.result === TaskResult.OK && <CheckBox color="success" />}
                                {task.result === TaskResult.NG && <Close color="error" />}
                            </ListItemAvatar>
                            <ListItemText primary={task.text} />
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
}