import { isDevelopment } from 'wcz-layout';

let pmtcUrl = "https://api.dev.wistron.eu/pmtc";
let peoplesoftUrl = "https://api.dev.wistron.eu/ps";
let fileUrl = "https://api.dev.wistron.eu/file";

if (!isDevelopment) {
    pmtcUrl = "https://api.wistron.eu/pmtc";
    peoplesoftUrl = "https://api.wistron.eu/ps";
    fileUrl = "https://api.wistron.eu/file";
}

//export microservices base URL
export { pmtcUrl, peoplesoftUrl, fileUrl };