import { Close, Delete } from "@mui/icons-material";
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { DateRange, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useState } from "react";
import { LayoutContext, fetchGet, fetchPut, isMobile, newGuid } from "wcz-layout";
import Device from "../../models/Device";
import DeviceDowntime from "../../models/DeviceDowntime";
import { pmtcUrl } from "../../utils/BaseUrl";
import LoadingButton from "../common/LoadingButton";

interface DowntimeCalendarDialogProps {
    deviceId: string,
    setDeviceId: (deviceId: string) => void
}

export default function DowntimeCalendarDialog(props: DowntimeCalendarDialogProps) {
    const { deviceId, setDeviceId } = props;
    const [calendarValue, setCalendarValue] = useState<DateRange<Date>>([null, null]);
    const { t } = useContext(LayoutContext);

    const { data: device = {} as Device, refetch } = useQuery<Device>(["device", deviceId], ({ signal }) => fetchGet(`${pmtcUrl}/v1/device/${deviceId}`, signal), {
        enabled: !!deviceId
    });

    const { mutate: update } = useMutation((data: Device) => fetchPut(pmtcUrl + "/v1/device/" + data.id, data), { onSuccess: () => refetch() });

    const handleOnSave = () => {
        const startDate: string = moment(calendarValue[0]).formatDate();
        if (!startDate) return;

        const endDate: string = moment(calendarValue[1] ?? calendarValue[0]).formatDate();

        update({
            ...device,
            downtimes: [...device.downtimes, { startDate: startDate, endDate: endDate, id: newGuid() }]
        });
    };

    const handleOnCheckRepeatEveryYear = (downtime: DeviceDowntime, checked: boolean) => {
        update({
            ...device,
            downtimes: device.downtimes.map(d => d.id === downtime.id ? ({ ...downtime, repeatEveryYear: checked }) : d),
        });
    };

    const handleOnRemove = (downtime: DeviceDowntime) => {
        update({
            ...device,
            downtimes: device.downtimes.filter(d => d.id !== downtime.id)
        });
    };

    return (
        <Dialog open={!!deviceId} onClose={() => setDeviceId("")} fullScreen={isMobile}>
            <Grid container justifyContent="space-between" sx={{ height: 50 }}>
                <Grid item>
                    <DialogTitle>{t("Downtimes")}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={() => setDeviceId("")}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                <Typography variant="caption">{t("MaintenanceIsNotGeneratedOnPublicHolidays")}</Typography>

                <StaticDateRangePicker
                    displayStaticWrapperAs="desktop"
                    disableHighlightToday
                    calendars={1}
                    value={calendarValue}
                    onChange={(newValue) => { setCalendarValue(newValue); }}

                />

                <LoadingButton onClick={handleOnSave} variant="outlined" fullWidth>{t("Save")}</LoadingButton>

                <List sx={{ mt: 2 }} dense>
                    {device.downtimes?.map(downtime =>
                        <ListItem
                            key={downtime.id}
                            secondaryAction={
                                <IconButton edge="end" onClick={() => handleOnRemove(downtime)}>
                                    <Delete />
                                </IconButton>
                            }>
                            <ListItemText primary={`${moment(downtime.startDate).formatDate()} ${t("To").toLowerCase()} ${moment(downtime.endDate).formatDate()}`} secondary={
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={downtime.repeatEveryYear} onChange={e => handleOnCheckRepeatEveryYear(downtime, e.target.checked)} size="small" />} label="Repeat every year" />
                                </FormGroup>
                            } />
                        </ListItem>
                    )}
                </List>
            </DialogContent>
        </Dialog>
    );
}