import { GridRowId } from '@mui/x-data-grid-premium';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { trimEnd } from 'lodash';
import { useContext } from 'react';
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from 'wcz-layout';
import { DepartmentContext } from '../contexts/DepartmentContext';
import { Shutdown } from '../models/Shutdown';
import { pmtcUrl } from '../utils/BaseUrl';

const queryKey: string = 'Shutdown';

export function useGetShutdowns<TQueryFnData = Shutdown[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>) {
    const { department } = useContext(DepartmentContext);

    return useQuery([queryKey], ({ signal }) => fetchGet(`${pmtcUrl}/v1/${queryKey}${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), options);
}

export function useGetShutdown<TQueryFnData = Shutdown, TError = string, TData = TQueryFnData>(id: GridRowId | undefined, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>) {
    return useQuery([queryKey, id], ({ signal }) => fetchGet(`${pmtcUrl}/v1/${queryKey}/${id}`, signal), options);
}

interface UseCreateOptions {
    onSuccess?: (data: Shutdown) => void,
    onError?: (message: string) => void,
}

export function useCreateShutdown(options?: UseCreateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Shutdown) => fetchPost(`${pmtcUrl}/v1/${queryKey}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousDataList = queryClient.getQueryData<Shutdown[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], [model, ...previousDataList]);

            return { previousDataList };
        },
        onError: (err: string, model, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: 'error' });
        },
        onSuccess: (model, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], [model, ...context.previousDataList ?? []]);
            }
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}

interface UseUpdateOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateShutdown(options?: UseUpdateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Shutdown) => fetchPut(`${pmtcUrl}/v1/${queryKey}/${model.id}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });
            await queryClient.cancelQueries({ queryKey: [queryKey, model.id] });

            const previousDataList = queryClient.getQueryData<Shutdown[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], previousDataList.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData<Shutdown>([queryKey, model.id]);
            if (previousData)
                queryClient.setQueryData([queryKey, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
                queryClient.setQueryData([queryKey, variables.id], context.previousData);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: 'error' });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteShutdown(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((id: GridRowId) => fetchDelete(`${pmtcUrl}/v1/${queryKey}/${id}`), {
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Shutdown[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], previousData.filter(prev => prev.id !== id));

                return { previousData };
            }
        },
        onError: (err: string, id, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: 'error' });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};