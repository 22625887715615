import { Button } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { LayoutContext } from 'wcz-layout';
import ShutdownTemplate from '../../models/ShutdownTemplate';
import { useGetShutdownTemplate, useUpdateShutdownTemplate } from '../../services/ShutdownTemplateService';
import { CustomDialog } from '../common/CustomDialog';
import { ShutdownTemplateForm } from './form/ShutdownTemplateForm';

interface EditShutdownTemplateProps {
    id: string;
    setId: (id: string) => void;
}

export const EditShutdownTemplate: React.FC<EditShutdownTemplateProps> = ({ id, setId }) => {
    const [template, setTemplate] = useState<ShutdownTemplate>({} as ShutdownTemplate);
    const { t } = useContext(LayoutContext);

    useGetShutdownTemplate(id, {
        enabled: !!id,
        onSuccess: data => setTemplate(data)
    });

    const { mutate, isLoading: isMutating } = useUpdateShutdownTemplate({
        onSuccess: () => {
            setTemplate({} as ShutdownTemplate);
            setId('');
        }
    });

    const handleSave = useCallback(() => {
        mutate({
            ...template,
            tasks: template.tasks.map((task, index) => ({ ...task, position: index }))
        });
    }, [template]);

    const handleOnClose = useCallback(() => setId(''), []);

    const canSave = useMemo(() => template.device && template.tasks.length > 0, [template]);

    return (
        <CustomDialog title={t('UpdateTemplate')} open={!!id} onClose={handleOnClose} color="primary" maxWidth="lg" actions={[
            <Button key="save" onClick={handleSave} disabled={isMutating || !canSave} variant="contained">{t('Save')}</Button>
        ]}>
            <ShutdownTemplateForm template={template} setTemplate={setTemplate} />
        </CustomDialog>
    );
};