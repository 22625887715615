import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import Device from "../../models/Device";
import { pmtcUrl } from "../../utils/BaseUrl";
import TrailTable from "../common/TrailTable";

interface DeviceTrailProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void
}

export default function DeviceTrail(props: DeviceTrailProps) {
    const { primaryKey, setPrimaryKey } = props;

    const { data = {} as Device } = useQuery<Device>(["device", primaryKey], ({ signal }) => fetchGet(`${pmtcUrl}/v1/device/${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    return <TrailTable primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} title={data.name} />;
}