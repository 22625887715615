import { Error } from "@mui/icons-material";
import { Card, CardActionArea, CardHeader, Container, Divider, List, ListSubheader, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LayoutContext, fetchGet } from "wcz-layout";
import { BottomPanel } from "../../components/common/BottomPanel";
import AddDefect from "../../components/defect/AddDefect";
import EditDefect from "../../components/defect/EditDefect";
import { Defect } from "../../models/Defect";
import Maintenance from "../../models/Maintenance";
import WrittenTag from "../../models/WrittenTag";
import { MaintenanceStatus } from "../../models/enums/MaintenanceStatus";
import { TaskResult } from "../../models/enums/TaskResult";
import { pmtcUrl } from "../../utils/BaseUrl";
import { isNfcAvailable, readNfc } from "../../utils/Nfc";
import { LastFinishedMaintenance } from "./editMaintenance/LastFinishedMaintenance";
import { TaskCard } from "./editMaintenance/TaskCard";

export default function EditMaintenance() {
    const { deviceId, templateTaskId } = useParams();
    const [maintenances, setMaintenances] = useState<Maintenance[]>([]);
    const [addDefectId, setAddDefectId] = useState<string>("");
    const [editDefectId, setEditDefectId] = useState<string>("");
    const { changeTitle, t } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { refetch: refetchMaintenancesDevice, isLoading } = useQuery<Maintenance[]>(["maintenances", "device", deviceId], ({ signal }) => fetchGet(`${pmtcUrl}/v1/maintenance?deviceId=${deviceId}&status=${MaintenanceStatus.NOT_STARTED}&status=${MaintenanceStatus.IN_PROGRESS}`, signal), {
        enabled: !!deviceId,
        onSuccess: (data) => {
            if (data.length) {
                setMaintenances(data);
                changeTitle(data[0].device.name);
            } else {
                changeTitle(t("Maintenances"));
            }
        },
    });

    const { data: defects = [], refetch: refetchDefects } = useQuery<Defect[]>(["defects", deviceId], ({ signal }) => fetchGet(`${pmtcUrl}/v1/defect?deviceId=${deviceId}&isFixed=false`, signal), {
        enabled: !!deviceId
    });

    const { refetch: refetchMaintenancesTemplateTask, isLoading: isLoadingTasks } = useQuery<Maintenance[]>(["maintenances", "templateTaskId", templateTaskId], ({ signal }) => fetchGet(`${pmtcUrl}/v1/maintenance?templateTaskId=${templateTaskId}&status=${MaintenanceStatus.NOT_STARTED}&status=${MaintenanceStatus.IN_PROGRESS}`, signal), {
        enabled: !!templateTaskId,
        onSuccess: (data) => {
            if (data.length)
                setMaintenances(data.map(d => ({ ...d, tasks: d.tasks.filter(t => t.templateTaskId === templateTaskId) })));

            changeTitle(t("Maintenances"));
        },
    });

    useEffect(() => {
        const ctrl = new AbortController();

        if (isNfcAvailable)
            readNfc({
                onSuccess: value => onResult(value),
                options: { signal: ctrl.signal }
            });

        return () => ctrl.abort();
    }, []);

    const onResult = useCallback((value: string) => {
        const writtenTag: WrittenTag = JSON.parse(value);
        if (writtenTag.templateTaskId)
            navigate(`/maintenance/task/templateTaskId/${writtenTag.templateTaskId}`);
        else if (writtenTag.id)
            navigate(`/maintenance/device/${writtenTag.id}`);
    }, []);

    const navigateHome = useCallback(() => navigate("/"), []);

    const navigateToShutdown = useCallback(() => navigate(`/shutdown/create/${deviceId}`), [deviceId]);

    const allTasksAnswered = useMemo(() => maintenances.every(m => m.tasks.every(t => t.result !== TaskResult.NA)), [maintenances]);

    const handleOnAddDefectClick = useCallback(() => deviceId && setAddDefectId(deviceId), [deviceId]);

    return (
        <Container>
            {!maintenances.length && (!isLoading || !isLoadingTasks) && <LastFinishedMaintenance deviceId={deviceId} />}

            <List sx={{ overflow: 'auto', height: { xs: "calc(100vh - 76px)", sm: "calc(100vh - 84px)" }, padding: 0, pb: 10 }}>
                {maintenances.map(maintenance =>
                    <Fragment key={maintenance.id}>
                        <ListSubheader>{t(maintenance.scheduleType)} {templateTaskId && maintenance.device.name}</ListSubheader>

                        {maintenance.tasks.map(task =>
                            <TaskCard
                                key={task.id}
                                task={task}
                                maintenance={maintenance}
                                refetchMaintenancesDevice={refetchMaintenancesDevice}
                                refetchMaintenancesTemplateTask={refetchMaintenancesTemplateTask}
                                deviceId={deviceId}
                                templateTaskId={templateTaskId}
                                maintenances={maintenances}
                                setMaintenances={setMaintenances}
                            />
                        )}
                    </Fragment>
                )}

                {!!defects.length &&
                    <Card sx={{ mx: 1, mt: 3, borderLeft: "solid 5px", borderLeftColor: 'red' }}>
                        <CardHeader title={t("Defects")} />
                        {defects.map((defect, index, array) =>
                            <CardActionArea key={defect.id} onClick={() => setEditDefectId(defect.id)}>
                                <Typography sx={{ p: 1.5, display: 'flex', alignItems: 'center' }}><Error color="error" sx={{ mr: 1 }} /> {defect.description}</Typography>
                                {index + 1 !== array.length && <Divider />}
                            </CardActionArea>
                        )}
                    </Card>
                }
            </List>

            <BottomPanel allTasksAnswered={allTasksAnswered} deviceId={deviceId} navigateHome={navigateHome} handleOnAddDefect={handleOnAddDefectClick} handleOnAddShutdown={navigateToShutdown} />

            <AddDefect id={addDefectId} setId={setAddDefectId} refetch={refetchDefects} />
            <EditDefect id={editDefectId} setId={setEditDefectId} refetch={refetchDefects} />
        </Container>
    );
}