import { CheckBox, CheckBoxOutlineBlank, Close } from "@mui/icons-material";
import { Button, Card, CardActionArea, CardActions, CardHeader, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Fragment, useContext } from "react";
import { FileViewer, useFile } from "wcz-file";
import { LayoutContext, fetchPut } from "wcz-layout";
import Maintenance from "../../../models/Maintenance";
import MaintenanceTask from "../../../models/MaintenanceTask";
import { TaskResult } from "../../../models/enums/TaskResult";
import { pmtcUrl } from "../../../utils/BaseUrl";

interface TaskCardProps {
    task: MaintenanceTask;
    maintenance: Maintenance;
    refetchMaintenancesDevice: () => void;
    refetchMaintenancesTemplateTask: () => void;
    deviceId: string | undefined;
    templateTaskId: string | undefined;
    maintenances: Maintenance[];
    setMaintenances: (maintenances: Maintenance[]) => void;
}

export const TaskCard: React.FC<TaskCardProps> = ({ task, maintenance, refetchMaintenancesDevice, refetchMaintenancesTemplateTask, deviceId, templateTaskId, maintenances, setMaintenances }) => {
    const { t, snackbar } = useContext(LayoutContext);
    const { uploadProgress, uploadFiles } = useFile({ subId: task.id });

    const { mutate, isLoading: isMutating } = useMutation((req: Maintenance) => fetchPut(pmtcUrl + "/v1/maintenance/" + req.id, req), {
        onError: (message: string) => {
            if (deviceId)
                refetchMaintenancesDevice();
            else if (templateTaskId)
                refetchMaintenancesTemplateTask();

            snackbar({ message, severity: "error" });
        },
        onMutate: (variables) => setMaintenances(maintenances.map(m => m.id === variables.id ? variables : m))
    });

    const updateTaskResult = (maintenance: Maintenance, task: MaintenanceTask, result: TaskResult) => {
        const maintenanceTasks: MaintenanceTask[] = maintenance.tasks.map(t => t.id === task.id ? { ...t, result: result } : t);

        const updatedMaintenance: Maintenance = {
            ...maintenance,
            tasks: maintenanceTasks
        };

        mutate(updatedMaintenance);
    };

    return (
        <Card variant="outlined" sx={{ m: 1 }}>
            <CardActionArea onClick={() => updateTaskResult(maintenance, task, task.result === TaskResult.OK ? TaskResult.NA : TaskResult.OK)} disabled={isMutating}>
                <CardHeader title={<Typography whiteSpace="break-spaces">{task.text}</Typography>} avatar={
                    <Fragment>
                        {task.result === TaskResult.NA && <CheckBoxOutlineBlank />}
                        {task.result === TaskResult.OK && <CheckBox color="success" />}
                        {task.result === TaskResult.NG && <Close color="error" />}
                    </Fragment>
                } />
            </CardActionArea>

            <FileViewer subId={task.id} sx={{ mx: 1 }} />
            <FileViewer subId={task.templateTaskId} disableActions sx={{ mx: 1 }} />

            <CardActions>
                <Button color="error" onClick={() => updateTaskResult(maintenance, task, TaskResult.NG)}>{t("NotGood")}</Button>

                <label htmlFor={`file-upload-${task.id}`}>
                    <input accept="*" id={`file-upload-${task.id}`} multiple type="file" hidden onChange={uploadFiles} disabled={!!uploadProgress} />
                    <Button color="inherit" component="span" disabled={!!uploadProgress}>
                        {t("UploadFile")}
                    </Button>
                </label>
            </CardActions>
        </Card>
    );
};