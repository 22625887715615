import { Devices } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { DataGridPremium, GridColDef, GridRowModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import { trimEnd } from "lodash";
import moment from "moment";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { LayoutContext, fetchGet, fetchPut } from "wcz-layout";
import { ChipInputCell, EditableHeader, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import { DevicesFilterMenu } from "../../components/common/DevicesFilterMenu";
import DetailPanel from "../../components/maintenance/DetailPanel";
import { DepartmentContext } from "../../contexts/DepartmentContext";
import CategoryDepartment from "../../models/CategoryDepartment";
import Device from "../../models/Device";
import Maintenance from "../../models/Maintenance";
import { MaintenanceStatus } from "../../models/enums/MaintenanceStatus";
import { ScheduleType } from "../../models/enums/ScheduleType";
import { pmtcUrl } from "../../utils/BaseUrl";

export default function Maintenances() {
    const { status } = useParams();
    const [devicesMenu, setDevicesMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const { user, changeTitle, t, i18n, snackbar } = useContext(LayoutContext);
    const apiRef = useGridApiRef();
    const { department } = useContext(DepartmentContext);

    const { data: maintenances = [], isLoading, refetch } = useQuery<Maintenance[]>(["maintenances"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/maintenance${department ? `?department=${trimEnd(department, "0")}` : ""}${status ? `${department ? "&" : "?"}status=${status}` : ""}`, signal), {
        enabled: !!user.department
    });

    useEffect(() => changeTitle(t("Maintenances")), [i18n.language]);

    useEffect(() => {
        if (!status)
            showAllMaintenances();

    }, [status]);

    const showAllMaintenances = () => {
        refetch();
        apiRef.current.setColumnVisibility("status", true);
        apiRef.current.setColumnVisibility("unfinishedReason", true);
    };

    const update = useMutation((data: Maintenance) => fetchPut(pmtcUrl + "/v1/maintenance/" + data.id, data), {
        onSuccess: () => snackbar({ message: t("Updated") })
    });

    const getStatusChipColor = (value: MaintenanceStatus) => {
        switch (value) {
            case MaintenanceStatus.FINISHED: return "success";
            case MaintenanceStatus.IN_PROGRESS: return "info";
            case MaintenanceStatus.NOT_STARTED: return "default";
            default: return "error";
        }
    };

    const columns: GridColDef[] = useMemo(() => [
        { field: 'device', headerName: t("Device"), width: 250, valueGetter: ({ value }) => value?.name },
        { field: 'category', headerName: t("Category"), width: 250, valueGetter: ({ value }) => value?.name },
        {
            field: 'departments', headerName: t("Departments"), width: 260, valueGetter: ({ row }) => row.category?.departments?.map((v: CategoryDepartment) => v.value),
            renderCell: params => <ChipInputCell params={params} />,
        },
        { field: 'scheduleType', headerName: t("Schedule"), width: 120, type: 'singleSelect', valueOptions: Object.values(ScheduleType), },
        {
            field: 'status', headerName: "Status", width: 130, type: 'singleSelect', valueOptions: Object.values(MaintenanceStatus),
            renderCell: ({ value }) => <Chip label={value} variant="outlined" color={getStatusChipColor(value)} />
        },
        { field: 'remark', headerName: t("Remark"), width: 250, editable: true, renderHeader: EditableHeader, },
        { field: 'createdOn', headerName: t("Created"), width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'finishedBy', headerName: t("FinishedBy"), width: 250 },
        { field: 'finished', headerName: t("Finished"), width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDateTime() },
    ] as GridColDef[], [i18n.language]);

    const processRowUpdate = async (row: GridRowModel<Maintenance>): Promise<Maintenance> => {
        await update.mutateAsync(row);
        return row;
    };

    const openDeviceFilter = useCallback((e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => setDevicesMenu({ mouseX: e.clientX, mouseY: e.clientY }), []);

    const handleOnDeviceSelect = useCallback((device: Device) => apiRef.current.setFilterModel({ items: [{ field: "device", operator: "equals", value: device.name }] }), []);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={maintenances} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} apiRef={apiRef}
                    processRowUpdate={processRowUpdate}
                    getDetailPanelContent={params => <DetailPanel params={params} />} getDetailPanelHeight={() => 200}
                    slotProps={{
                        toolbar: {
                            hideAddRecord: true, viewKey: "maintenances", export: true,
                            items: [{
                                title: t("FilterDevices"), icon: <Devices />, onClick: openDeviceFilter
                            }]
                        } as GridToolbarProps
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                status: !status,
                                unfinishedReason: !(status === MaintenanceStatus.NOT_STARTED || status === MaintenanceStatus.IN_PROGRESS),
                                finishedBy: !(status === MaintenanceStatus.NOT_STARTED || status === MaintenanceStatus.IN_PROGRESS),
                                finished: !(status === MaintenanceStatus.NOT_STARTED || status === MaintenanceStatus.IN_PROGRESS),
                            },
                        },
                    }}
                />
            </TableContainer>

            <DevicesFilterMenu contextMenu={devicesMenu} setContextMenu={setDevicesMenu} devices={maintenances.flatMap(m => m.device).sort((a, b) => a.name.localeCompare(b.name))} onSelect={handleOnDeviceSelect} />
        </Fragment>
    );
}