import { Assignment, Build, Category, Devices, Error, Home, PowerSettingsNew } from '@mui/icons-material';
import { brown, indigo } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FileProvider } from 'wcz-file';
import { LayoutProvider, LeftDrawerItem } from 'wcz-layout';
import packageJson from '../package.json';
import { DepartmentProvider } from './contexts/DepartmentContext';
import Categories from './pages/Categories';
import CreateShutdown from './pages/CreateShutdown';
import DefectAutomate from './pages/defect/DefectAutomate';
import DefectDetail from './pages/defect/DefectDetail';
import Defects from './pages/defect/Defects';
import DevicesPage from './pages/Devices';
import HomePage from './pages/HomePage';
import CreateMaintenance from './pages/maintenance/CreateMaintenance';
import EditMaintenance from './pages/maintenance/EditMaintenance';
import Maintenances from './pages/maintenance/Maintenances';
import Shutdowns from './pages/Shutdowns';
import ShutdownTemplates from './pages/ShutdownTemplates';
import Templates from './pages/Templates';
import { fileUrl } from './utils/BaseUrl';

const leftDrawerItems: LeftDrawerItem[] = [
    { title: "Home", path: "/", icon: <Home fontSize="large" /> },
    { title: "Categories", path: "/categories", icon: <Category fontSize="large" /> },
    { title: "Devices", path: "/devices", icon: <Devices fontSize="large" /> },
    { title: "Templates", path: "/templates", icon: <Assignment fontSize="large" /> },
    { title: "Defects", path: "/defects", icon: <Error fontSize="large" /> },
    { title: "Maintenances", path: "/maintenance", icon: <Build fontSize="large" /> },
    { title: "Shutdowns", path: "/shutdowns", icon: <PowerSettingsNew fontSize="large" /> }
];

export default function App() {
    return (
        <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={indigo[500]} secondaryColor={brown[500]} leftDrawerItems={leftDrawerItems}>
                    <FileProvider baseUrl={fileUrl}>
                        <DepartmentProvider>
                            <Routes>
                                <Route path='/' element={<HomePage />} />
                                <Route path='/categories' element={<Categories />} />
                                <Route path='/devices' element={<DevicesPage />} />
                                <Route path='/defects' element={<Defects />} />
                                <Route path='/defect/:id' element={<DefectDetail />} />
                                <Route path='/defects/:isFixed' element={<Defects />} />
                                <Route path='/defect/automate' element={<DefectAutomate />} />
                                <Route path='/templates' element={<Templates />} />
                                <Route path='/maintenance' element={<Maintenances />} />
                                <Route path='/maintenance/:status' element={<Maintenances />} />
                                <Route path='/maintenance/create' element={<CreateMaintenance />} />
                                <Route path='/maintenance/device/:deviceId' element={<EditMaintenance />} />
                                <Route path='/maintenance/task/templateTaskId/:templateTaskId' element={<EditMaintenance />} />
                                <Route path='/shutdowns' element={<Shutdowns />} />
                                <Route path='/shutdowns/:onlyActive' element={<Shutdowns />} />
                                <Route path='/shutdown/create' element={<CreateShutdown />} />
                                <Route path='/shutdown/create/:deviceId' element={<CreateShutdown />} />
                                <Route path='/shutdown/templates' element={<ShutdownTemplates />} />
                            </Routes>
                        </DepartmentProvider>
                    </FileProvider>
                </LayoutProvider>
            </LocalizationProvider>
        </BrowserRouter>
    );
}