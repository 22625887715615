import { Alert, AlertTitle, Box, Tooltip, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import moment from "moment";
import 'moment/locale/cs';
import 'moment/locale/en-gb';
import { useContext, useMemo } from "react";
import { LayoutContext, fetchGet } from "wcz-layout";
import Maintenance from "../../../models/Maintenance";
import { MaintenanceStatus } from "../../../models/enums/MaintenanceStatus";
import { pmtcUrl } from "../../../utils/BaseUrl";

interface LastFinishedMaintenanceProps {
    deviceId: string | undefined;
}

export const LastFinishedMaintenance: React.FC<LastFinishedMaintenanceProps> = ({ deviceId }) => {
    const { t, i18n } = useContext(LayoutContext);

    const { data: maintenances = [] } = useQuery<Maintenance[]>(["maintenances", "device", deviceId, `${MaintenanceStatus.FINISHED}`], ({ signal }) => fetchGet(`${pmtcUrl}/v1/maintenance?deviceId=${deviceId}&status=${MaintenanceStatus.FINISHED}`, signal), {
        enabled: !!deviceId
    });

    const lastMaintenance = useMemo(() => {
        const sortedMaintenances = orderBy(maintenances, ['createdDate'], ['desc']);
        if (sortedMaintenances.length === 0) return null;
        return sortedMaintenances[0];
    }, [maintenances]);

    const finishedDate = useMemo(() => {
        if (!lastMaintenance) return "";

        const localLocale = moment(lastMaintenance.finished);
        localLocale.locale(i18n.language);
        return localLocale.fromNow();
    }, [i18n.language, lastMaintenance]);

    if (lastMaintenance)
        return (
            <Box sx={{ mt: 2 }}>
                <Typography textAlign="center" sx={{ mb: 2 }}>{t("NoMaintenanceAvailable")}</Typography>

                <Alert severity="info">
                    <AlertTitle sx={{ fontWeight: 600 }}>{t("LastFinishedMaintenance")}</AlertTitle>
                    <Tooltip title={moment(lastMaintenance.finished).formatDateTime()}>
                        <Typography variant="body2"><b>{t("Finished")}</b>: {finishedDate}</Typography>
                    </Tooltip>
                    <Typography variant="body2"><b>{t("FinishedBy")}</b>: {lastMaintenance.finishedBy}</Typography>
                </Alert>
            </Box>
        );

    return null;
};