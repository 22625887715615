export interface WriteNfcProps {
    value: string;
    options?: NDEFWriteOptions;
    makeReadOnly?: boolean;
    onSuccess?: () => void;
    onError?: (error: string) => void;
}

export async function writeNfc(props: WriteNfcProps) {
    const { value, onSuccess, onError, options, makeReadOnly } = props;

    const ndef = new NDEFReader();
    try {
        await ndef.write(value, options);
        if (makeReadOnly)
            await ndef.makeReadOnly();
        if (onSuccess) onSuccess();
    } catch (error) {
        if (onError) onError(`Operation failed: ${error}`);
    }
}

export interface ReadNfcProps {
    onSuccess: (value: string) => void;
    onError?: (error: string) => void;
    options?: NDEFScanOptions;
}

export function readNfc(props: ReadNfcProps) {
    const { onSuccess, onError, options } = props;

    const ndef = new NDEFReader();

    ndef.scan(options).then(() => {
        ndef.onreadingerror = () => {
            if (onError) onError("Error! Cannot read data from the NFC tag.");
        };
        ndef.onreading = ({ message }) => {
            for (const record of message.records) {
                const textDecoder = new TextDecoder(record.encoding);
                onSuccess(textDecoder.decode(record.data));
            }
        };
    }).catch(error => {
        if (onError) onError(`Error! Scan failed to start: ${error}.`);
    });
}

export const isNfcAvailable: boolean = "NDEFReader" in window;