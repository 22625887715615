import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import Category from "../../models/Category";
import { pmtcUrl } from "../../utils/BaseUrl";
import TrailTable from "../common/TrailTable";

interface CategoryTrailProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void
}

export default function CategoryTrail(props: CategoryTrailProps) {
    const { primaryKey, setPrimaryKey } = props;

    const { data = {} as Category } = useQuery<Category>(["category", primaryKey], ({ signal }) => fetchGet(`${pmtcUrl}/v1/category/${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    return <TrailTable primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} title={data.name} />;
}