import { Add, Create, Devices, History, Info } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { trimEnd } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchGet, LayoutContext } from "wcz-layout";
import { ChipInputCell, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import AddDefect from "../../components/defect/AddDefect";
import DefectTrail from "../../components/defect/DefectTrail";
import EditDefect from "../../components/defect/EditDefect";
import SelectDeviceMenu, { SelectDeviceMenuModel } from "../../components/defect/SelectDeviceMenu";
import { DepartmentContext } from "../../contexts/DepartmentContext";
import { Defect } from "../../models/Defect";
import { pmtcUrl } from "../../utils/BaseUrl";
import { DevicesFilterMenu } from "../../components/common/DevicesFilterMenu";
import Device from "../../models/Device";
import CategoryDepartment from "../../models/CategoryDepartment";

export default function Defects() {
    const { isFixed } = useParams();
    const [addDefectMenu, setAddDefectMenu] = useState<SelectDeviceMenuModel | null>(null);
    const [addId, setAddId] = useState("");
    const [editId, setEditId] = useState("");
    const [trailId, setTrailId] = useState<string>("");
    const [devicesMenu, setDevicesMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const { changeTitle, user, t, i18n } = useContext(LayoutContext);
    const apiRef = useGridApiRef();
    const { department } = useContext(DepartmentContext);
    const navigate = useNavigate();

    const { data: defects = [], refetch, isLoading } = useQuery<Defect[]>(["defects"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/defect${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!user.department
    });

    useEffect(() => changeTitle(t("Defects")), [i18n.language]);

    useEffect(() => {
        if (isFixed)
            setFilterModel();
        else
            clearFilterModel();

    }, [isFixed]);

    const setFilterModel = () => {
        apiRef.current.setFilterModel({
            items: [
                { field: 'isFixed', operator: 'is', value: isFixed }
            ]
        });
    };

    const clearFilterModel = () => apiRef.current.setFilterModel({ items: [] });

    const redirectToDetail = useCallback((id: string) => () => navigate(`/defect/${id}`), []);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="edit" icon={<Create />} label={t("Edit")} onClick={() => setEditId(params.id)} showInMenu />,
                <GridActionsCellItem key="history" icon={<History />} label={t("History")} onClick={() => setTrailId(params.id)} showInMenu />,
                <GridActionsCellItem key="detail" icon={<Info />} label={t("Detail")} onClick={redirectToDetail(params.id)} showInMenu />,
            ],
        },
        { field: 'hasFile', headerName: t("HasFile"), width: 100, type: 'boolean', },
        { field: 'description', headerName: t("Description"), width: 350, },
        { field: 'isFixed', headerName: t("IsFixed"), width: 130, type: 'boolean', },
        { field: 'device', headerName: t("Device"), width: 220, valueGetter: ({ value }) => value?.name },
        {
            field: 'departments', headerName: t("Departments"), width: 260, valueGetter: ({ row }) => row.device?.category?.departments?.map((v: CategoryDepartment) => v.value),
            renderCell: params => <ChipInputCell params={params} />,
        },
        { field: 'repairDescription', headerName: t("RepairDescription"), width: 350, },
        { field: 'createdOn', headerName: t("Created"), width: 200, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'createdBy', headerName: t("CreatedBy"), width: 220 },
        { field: 'finishedOn', headerName: t("FinishedDate"), width: 200, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'finishedBy', headerName: t("FinishedBy"), width: 220, },
    ] as GridColDef[], [i18n.language]);

    const openDeviceFilter = useCallback((e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => setDevicesMenu({ mouseX: e.clientX, mouseY: e.clientY }), []);

    const handleOnDeviceSelect = useCallback((device: Device) => apiRef.current.setFilterModel({ items: [{ field: "device", operator: "equals", value: device.name }] }), []);

    return (
        <React.Fragment>
            <TableContainer>
                <DataGridPremium rows={defects} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} apiRef={apiRef} onRowDoubleClick={({ id }) => setEditId(id as string)}
                    slotProps={{
                        toolbar: {
                            items: [
                                { title: t("DataGrid.AddRecord"), onClick: e => setAddDefectMenu({ mouseX: e.clientX, mouseY: e.clientY }), icon: <Add /> },
                                { title: t("Devices"), icon: <Devices />, onClick: openDeviceFilter }
                            ]
                        } as GridToolbarProps
                    }} />
            </TableContainer>

            <AddDefect id={addId} setId={setAddId} refetch={refetch} />
            <EditDefect id={editId} setId={setEditId} refetch={refetch} />
            <SelectDeviceMenu menu={addDefectMenu} setMenu={setAddDefectMenu} setDeviceId={setAddId} />
            <DefectTrail primaryKey={trailId} setPrimaryKey={setTrailId} />
            <DevicesFilterMenu contextMenu={devicesMenu} setContextMenu={setDevicesMenu} devices={defects.flatMap(d => d.device).filter(device => !!device?.name).sort((a, b) => a.name.localeCompare(b.name))} onSelect={handleOnDeviceSelect} />
        </React.Fragment>
    );
}