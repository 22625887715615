import { Add, Create, History } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import { trimEnd } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { fetchDelete, fetchGet, LayoutContext } from "wcz-layout";
import { GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import AddTemplate from "../components/template/AddTemplate";
import EditTemplate from "../components/template/EditTemplate";
import TemplateTrail from "../components/template/TemplateTrail";
import { DepartmentContext } from "../contexts/DepartmentContext";
import { ScheduleType } from "../models/enums/ScheduleType";
import Template from "../models/Template";
import { pmtcUrl } from "../utils/BaseUrl";

export default function Templates() {
    const [addOpen, setAddOpen] = useState(false);
    const [editId, setEditId] = useState("");
    const [trailId, setTrailId] = useState<string>("");
    const { changeTitle, snackbar, user, t, i18n } = useContext(LayoutContext);
    const { department } = useContext(DepartmentContext);

    const { data: templates = [], refetch, isLoading } = useQuery<Template[]>(["templates"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/template${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!user.department
    });

    useEffect(() => changeTitle(t("Templates")), [i18n.language]);

    const remove = useMutation((id: GridRowId) => fetchDelete(pmtcUrl + "/v1/template/" + id), {
        onSuccess: () => {
            snackbar({ message: t("Deleted") });
            refetch();
        }
    });

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="edit" icon={<Create />} label={t("Edit")} onClick={() => setEditId(params.id)} showInMenu />,
                <GridDeleteCellItem key="remove" id={params.id} remove={remove} showInMenu />,
                <GridActionsCellItem icon={<History />} label={t("History")} onClick={() => setTrailId(params.id)} showInMenu />,
            ],
        },
        { field: 'category', headerName: t("Category"), width: 300, valueGetter: ({ value }) => value?.name, },
        { field: 'scheduleType', headerName: t("Schedule"), width: 120, type: 'singleSelect', valueOptions: Object.values(ScheduleType), },
        { field: 'isActive', headerName: t("Active"), width: 110, type: 'boolean' },
    ] as GridColDef[], [i18n.language]);

    return (
        <React.Fragment>
            <TableContainer>
                <DataGridPremium rows={templates} columns={columns} components={{ Toolbar: GridToolbar }} loading={isLoading} onRowDoubleClick={({ id }) => setEditId(id as string)}
                    componentsProps={{
                        toolbar: { items: [{ title: t("DataGrid.AddRecord"), icon: <Add />, onClick: () => setAddOpen(true) }], } as GridToolbarProps
                    }} />
            </TableContainer>

            <AddTemplate open={addOpen} setOpen={setAddOpen} refetch={refetch} />
            <EditTemplate id={editId} setId={setEditId} refetch={refetch} />
            <TemplateTrail primaryKey={trailId} setPrimaryKey={setTrailId} />
        </React.Fragment>
    );
}