import { Autocomplete, Checkbox, FormControl, Grid, Input, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "wcz-layout";
import Category from "../../../../models/Category";
import { ScheduleType } from "../../../../models/enums/ScheduleType";
import Template from "../../../../models/Template";

const allHours: string[] = ["05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"];
const allMonthDays: string[] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];

interface SchedulerProps {
    template: Template,
    setTemplate: (template: Template) => void,
    categories: Category[]
}

export default function Scheduler(props: SchedulerProps) {
    const { template, setTemplate, categories } = props;
    const [hours, setHours] = useState([] as string[]);
    const [weekDays, setWeekDays] = useState([] as string[]);
    const [monthDays, setMonthDays] = useState([] as string[]);
    const [months, setMonths] = useState([] as string[]);
    const { t } = useContext(LayoutContext);

    useEffect(() => {
        if (template.cronSchedule)
            parseCronExpression();

        // eslint-disable-next-line
    }, [template.cronSchedule]);

    useEffect(() => {
        if (template.scheduleType)
            setCronExpression();

        // eslint-disable-next-line
    }, [template.scheduleType, hours, weekDays, monthDays, months]);

    const getShort = (value: string): string => {
        return value.slice(0, 3).toUpperCase();
    };

    const setCronExpression = () => {
        if (template.scheduleType === ScheduleType.DAILY && hours.length) {
            const stringifyHours: string = hours.toString();
            const cronExpression: string = "* * " + stringifyHours + " ? * * *";
            return setTemplate({ ...template, cronSchedule: cronExpression });
        }

        if (template.scheduleType === ScheduleType.WEEKLY && weekDays.length) {
            const stringifyWeekdays: string = weekDays.toString();
            const cronExpression: string = "* * * ? * " + stringifyWeekdays + " *";
            return setTemplate({ ...template, cronSchedule: cronExpression });
        }

        if (template.scheduleType === ScheduleType.MONTHLY && monthDays.length) {
            const stringifyMonthdays: string = monthDays.toString();
            const cronExpression: string = "* * * " + stringifyMonthdays + " * ? *";
            return setTemplate({ ...template, cronSchedule: cronExpression });
        }

        if (template.scheduleType === ScheduleType.YEARLY && months.length) {
            const stringifyMonths: string = months.toString();
            const cronExpression: string = "* * * 1 " + stringifyMonths + " ? *";
            return setTemplate({ ...template, cronSchedule: cronExpression });
        }

        if (template.scheduleType === ScheduleType.NEVER)
            setTemplate({ ...template, cronSchedule: "" });
    };

    const parseCronExpression = () => {
        if (template.scheduleType === ScheduleType.DAILY) {
            const expression: string[] = template.cronSchedule.split(/[ ,]+/);
            const workDaysFromCronExpression: string[] = [];

            allHours.forEach(hour => {
                if (expression.includes(hour))
                    workDaysFromCronExpression.push(hour);
            });

            return setHours(workDaysFromCronExpression);
        }

        if (template.scheduleType === ScheduleType.WEEKLY) {
            const expression: string = template.cronSchedule;
            const weekdaysFromCronExpression: string[] = [];

            moment.weekdays(true).forEach(day => {
                if (expression.includes(getShort(day)))
                    weekdaysFromCronExpression.push(getShort(day));
            });

            return setWeekDays(weekdaysFromCronExpression);
        }

        if (template.scheduleType === ScheduleType.MONTHLY) {
            const expression: string[] = template.cronSchedule.split(/[ ,]+/);
            const monthDaysFromCronExpression: string[] = [];

            allMonthDays.forEach(day => {
                if (expression.includes(day.toString()))
                    monthDaysFromCronExpression.push(day);
            });

            return setMonthDays(monthDaysFromCronExpression);
        }

        if (template.scheduleType === ScheduleType.YEARLY) {
            const expression: string = template.cronSchedule;
            const monthsFromCronExpression: string[] = [];

            moment.months().forEach(month => {
                if (expression.includes(getShort(month)))
                    monthsFromCronExpression.push(getShort(month));
            });

            return setMonths(monthsFromCronExpression);
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        value={template.category}
                        getOptionLabel={o => o.name}
                        options={categories}
                        autoHighlight
                        onChange={(e, value) => setTemplate({ ...template, category: value })}
                        renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Category")} required />}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        value={template.isActive ? "Active" : "Inactive"}
                        options={["Active", "Inactive"]}
                        onChange={(e, value) => setTemplate({ ...template, isActive: value === "Active" })}
                        renderInput={(params) => <TextField {...params} label={t("Active")} fullWidth variant="standard" required />}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required variant="standard">
                        <InputLabel>{t("Schedule")}</InputLabel>
                        <Select onChange={e => setTemplate(({ ...template, scheduleType: e.target.value as ScheduleType }))} value={template.scheduleType}>
                            {Object.values(ScheduleType).map(scheduleType =>
                                <MenuItem key={scheduleType} value={scheduleType}>{t(scheduleType)}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    {template.scheduleType === ScheduleType.DAILY &&
                        <FormControl fullWidth required variant="standard">
                            <InputLabel>{t("Hours")}</InputLabel>
                            <Select onChange={e => setHours(e.target.value as string[])} value={hours} multiple input={<Input />} renderValue={(selected) => selected.join(', ')}>
                                {allHours.map(hour => <MenuItem key={hour} value={hour} dense>
                                    <Checkbox checked={hours.indexOf(hour) > -1} size="small" />
                                    <ListItemText primary={hour} />
                                </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    }

                    {template.scheduleType === ScheduleType.WEEKLY &&
                        <FormControl fullWidth required variant="standard">
                            <InputLabel>{t("Days")}</InputLabel>
                            <Select onChange={e => setWeekDays(e.target.value as string[])} value={weekDays} multiple input={<Input />} renderValue={(selected) => selected.join(', ')}>
                                {moment.weekdays(true).map(day =>
                                    <MenuItem key={day} value={getShort(day)} dense>
                                        <Checkbox checked={weekDays.indexOf(getShort(day)) > -1} size="small" />
                                        <ListItemText primary={t(day)} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    }

                    {template.scheduleType === ScheduleType.MONTHLY &&
                        <FormControl fullWidth required variant="standard">
                            <InputLabel>{t("Days")}</InputLabel>
                            <Select onChange={e => setMonthDays(e.target.value as string[])} value={monthDays} multiple input={<Input />} renderValue={(selected) => selected.join(', ')} >
                                {allMonthDays.map(day =>
                                    <MenuItem key={day} value={day} dense>
                                        <Checkbox checked={monthDays.indexOf(day) > -1} size="small" />
                                        <ListItemText primary={day} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    }

                    {template.scheduleType === ScheduleType.YEARLY &&
                        <FormControl fullWidth required variant="standard">
                            <InputLabel>{t("Months")}</InputLabel>
                            <Select onChange={e => setMonths(e.target.value as string[])} value={months} multiple input={<Input />} renderValue={(selected) => selected.join(', ')}>
                                {moment.months().map(month =>
                                    <MenuItem key={month} value={getShort(month)} dense>
                                        <Checkbox checked={months.indexOf(getShort(month)) > -1} size="small" />
                                        <ListItemText primary={t(month)} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}