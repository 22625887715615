import { Search } from '@mui/icons-material';
import { Box, InputAdornment, List, ListItemButton, ListItemText, Menu, TextField } from '@mui/material';
import { uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LayoutContext } from 'wcz-layout';
import Device from '../../models/Device';

interface DevicesFilterMenuProps {
    contextMenu: { mouseX: number; mouseY: number; } | null;
    setContextMenu: (contextMenu: { mouseX: number; mouseY: number; } | null) => void;
    devices: Device[];
    onSelect: (device: Device) => void;
}

export const DevicesFilterMenu: React.FC<DevicesFilterMenuProps> = ({ contextMenu, setContextMenu, devices, onSelect }) => {
    const { t } = useContext(LayoutContext);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<string>("");

    useEffect(() => {
        if (contextMenu)
            setTimeout(() => document.getElementById("devices-filter")?.focus(), 300);
    }, [contextMenu]);

    const closeMenu = useCallback(() => setContextMenu(null), []);

    const handleSearchTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value), []);

    const handleOnClick = useCallback((device: Device) => () => {
        setSelectedValue(device.id);
        onSelect(device);
        setContextMenu(null);
    }, [onSelect]);

    return (
        <Menu open={contextMenu !== null} onClose={closeMenu} anchorReference="anchorPosition" variant="menu"
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
            <Box sx={{ width: 320, maxHeight: 380, p: 1 }} role="presentation">
                <TextField id="devices-filter" label={t("Devices")} fullWidth variant="standard" value={searchText} onChange={handleSearchTextChange}
                    InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }} />

                <List dense>
                    {uniqBy(devices, "id").filter(d => d.name.toLowerCase().includes(searchText.toLowerCase())).map(device =>
                        <ListItemButton key={device.id} onClick={handleOnClick(device)} selected={device.id === selectedValue}>
                            <ListItemText primary={device.name} />
                        </ListItemButton>
                    )}
                </List>
            </Box>
        </Menu>
    );
};