import React, { createContext, useContext, useMemo } from 'react';
import { LayoutContext, hasRole } from 'wcz-layout';

interface DepartmentContextInterface {
    department: string;
}

export const DepartmentContext = createContext({} as DepartmentContextInterface);

interface DepartmentProviderProps {
    children?: React.ReactNode,
}

export function DepartmentProvider(props: Readonly<DepartmentProviderProps>) {
    const { user } = useContext(LayoutContext);
    const department = useMemo(() => hasRole(["WCZ_App.PMTC-Admin", "wcz-developers"]) ? "" : user.department, [user]);

    const providerValue: DepartmentContextInterface = useMemo(() => ({ department: department }), [department]);

    return (
        <DepartmentContext.Provider value={providerValue}>
            {props.children}
        </DepartmentContext.Provider>
    );
}