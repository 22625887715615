import { Add, ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import { Fragment, useCallback, useContext } from 'react';
import { LayoutContext, newGuid } from 'wcz-layout';
import Device from '../../../models/Device';
import ShutdownTemplate from '../../../models/ShutdownTemplate';
import ShutdownTemplateTask from '../../../models/ShutdownTemplateTask';
import TemplateTask from '../../../models/TemplateTask';
import { useGetDevices } from '../../../services/DeviceService';

interface ShutdownTemplateFormProps {
    template: ShutdownTemplate,
    setTemplate: (template: ShutdownTemplate) => void,
}

export const ShutdownTemplateForm: React.FC<ShutdownTemplateFormProps> = ({ template, setTemplate }) => {
    const { t } = useContext(LayoutContext);

    const { data: devices = [] } = useGetDevices({
        enabled: !!template.id
    });

    const handleDeviceChange = useCallback((e: React.SyntheticEvent<Element, Event>, value: Device) => setTemplate({ ...template, device: value }), [template]);

    const handleTextChange = useCallback((task: ShutdownTemplateTask) => (e: React.ChangeEvent<HTMLInputElement>) =>
        setTemplate({
            ...template,
            tasks: template.tasks.map(t => t.id === task.id ? { ...t, text: e.target.value } : t)
        }), [template]);

    const handleOnIndexUp = useCallback((index: number) => {
        const updatedTasks = template.tasks;

        const lowerElement = updatedTasks[index - 1];
        const element = updatedTasks[index];

        updatedTasks[index - 1] = element;
        updatedTasks[index] = lowerElement;

        setTemplate({ ...template, tasks: updatedTasks });
    }, [template]);

    const handleOnIndexDown = useCallback((index: number) => {
        const updatedTasks = template.tasks;

        const higherElement = updatedTasks[index + 1];
        const element = updatedTasks[index];

        updatedTasks[index + 1] = element;
        updatedTasks[index] = higherElement;

        setTemplate({ ...template, tasks: updatedTasks });
    }, [template]);

    const addTask = useCallback(() => setTemplate({
        ...template, tasks: [...template.tasks, {
            id: newGuid(),
            text: ""
        }]
    }), [template]);

    const removeTask = useCallback((task: TemplateTask) => () => setTemplate({
        ...template, tasks: template.tasks.filter(q => q.id !== task.id)
    }), [template]);

    return (
        <Fragment>
            <Autocomplete
                value={template.device ?? null}
                options={devices}
                getOptionLabel={(option) => option.name}
                autoHighlight
                disableClearable
                onChange={handleDeviceChange}
                renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Device")} required sx={{ mb: 5 }} />}
            />

            {template.tasks?.map((task, index, array) =>
                <Grid container key={task.id}>
                    <Grid item md={1} xs={2}>
                        {!!index &&
                            <IconButton onClick={() => handleOnIndexUp(index)}>
                                <ArrowUpward />
                            </IconButton>
                        }

                        {index + 1 !== array.length &&
                            <IconButton onClick={() => handleOnIndexDown(index)}>
                                <ArrowDownward />
                            </IconButton>
                        }
                    </Grid>

                    <Grid item md={11} xs={10} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField placeholder={t('Task')} fullWidth variant="standard" multiline required value={task.text} onChange={handleTextChange(task)} />
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                {index + 1 === array.length &&
                                    <IconButton onClick={addTask}>
                                        <Add />
                                    </IconButton>
                                }
                                {array.length !== 1 &&
                                    <IconButton onClick={removeTask(task)}>
                                        <Delete />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Fragment >
    );
};