import { ContentCopy, QrCode as QrCodeIcon } from "@mui/icons-material";
import { Box, Dialog, DialogContent, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from "@mui/material";
import QrCode from 'qrcode.react';
import { Fragment, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import WrittenTag from "../../models/WrittenTag";
import { TagMenu } from "../../pages/Devices";

interface UpdateTagProps {
    tagMenu: TagMenu,
    setTagMenu: (tag: TagMenu) => void
}

export default function DeviceQrCode(props: UpdateTagProps) {
    const { tagMenu, setTagMenu } = props;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const { t, snackbar } = useContext(LayoutContext);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify({ id: tagMenu.device.id } as WrittenTag));
        snackbar({ message: t("CopiedToClipboard") });
        onClose();
    };

    const onClose = () => {
        setTagMenu({} as TagMenu);
        setDialogOpen(false);
    };

    if (!tagMenu.type)
        return null;

    return (
        <Fragment>
            <Menu open={!!tagMenu.type} onClose={onClose} anchorReference="anchorPosition" variant="menu"
                anchorPosition={tagMenu !== null ? { top: tagMenu.mouseY, left: tagMenu.mouseX } : undefined}>
                <Box sx={{ width: 240 }} role="presentation">
                    <MenuList disablePadding>
                        <MenuItem onClick={() => setDialogOpen(true)}>
                            <ListItemIcon>
                                <QrCodeIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t("Display")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={copyToClipboard}>
                            <ListItemIcon>
                                <ContentCopy fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t("CopyContent")}</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Box>
            </Menu>

            <Dialog fullWidth open={dialogOpen} onClose={onClose}>
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 1 }}>{t("SaveAndPrintTheQrCode")}</Typography>
                <DialogContent>
                    <QrCode value={JSON.stringify({ id: tagMenu.device.id } as WrittenTag)} size={550} />
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}