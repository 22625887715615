import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

interface LoadingButtonProps {
    onClick: () => void,
    children?: React.ReactNode,
    variant?: 'text' | 'outlined' | 'contained',
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    disabled?: boolean,
    fullWidth?: boolean
}

export default function LoadingButton(props: LoadingButtonProps) {
    const isFetching = !!useIsFetching();
    const isMutating = !!useIsMutating();

    return (
        <Button variant={props.variant} color={props.color} disabled={isFetching || isMutating || props.disabled} onClick={props.onClick} fullWidth={props.fullWidth}>
            {(isFetching || isMutating) ? <CircularProgress size={24} color="success" /> : props.children}
        </Button>
    );
}