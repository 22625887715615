import { Block, Close, DateRange, Delete, Done, History, Nfc, QrCode } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowModel, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { trimEnd } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchGet, isAndroid, isMobile, LayoutContext, newGuid } from "wcz-layout";
import { AutocompleteEditInputCell, ChipInputCell, EditableHeader, editInputCellValidation, GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import DeviceQrCode from "../components/device/DeviceQrCode";
import DeviceTrail from "../components/device/DeviceTrail";
import DowntimeCalendarDialog from "../components/device/DowntimeCalendarDialog";
import UpdateTag from "../components/device/UpdateTag";
import { DepartmentContext } from "../contexts/DepartmentContext";
import Device, { DeviceOptions } from "../models/Device";
import DevicePersonInCharge from "../models/DevicePersonInCharge";
import { DeviceStatus } from "../models/enums/DeviceStatus";
import { useCreateDevice, useDeleteDevice, useGetDevices, useUpdateDevice } from "../services/DeviceService";
import { peoplesoftUrl, pmtcUrl } from "../utils/BaseUrl";
import { Button } from "../components/common/Button";
import MassDowntimeCalendarDialog from "../components/device/MassDowntimeCalendarDialog";

const deviceOptionsInit: DeviceOptions = { manufacturers: [], partNumbers: [], categories: [], locations: [] };

export interface Tag {
    type: "QR" | "NFC",
    device: Device
}

export interface TagMenu extends Tag {
    mouseX: number,
    mouseY: number
}

export default function Devices() {
    const [tag, setTag] = useState<Tag>({} as Tag);
    const [qrMenu, setQrMenu] = useState<TagMenu>({} as TagMenu);
    const [downtimeCalendarDeviceId, setDowntimeCalendarDeviceId] = useState<string>("");
    const [trailId, setTrailId] = useState<string>("");
    const [deviceDowntimes, setDeviceDowntimes] = useState<"off" | "selection" | "on">("off");
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const { changeTitle, user, t, i18n } = useContext(LayoutContext);
    const apiRef = useGridApiRef();
    const { department } = useContext(DepartmentContext);

    const { data: devices = [], refetch, isLoading } = useGetDevices({
        enabled: !!user.department
    });

    const { data: options = deviceOptionsInit, refetch: refetchOptions } = useQuery<DeviceOptions>(["deviceOptions"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/device/options${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!user.department
    });

    const { data: employees = [] } = useQuery<DevicePersonInCharge[]>(["employees"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=status==Active`, signal), {
        select: (data: any) => data.map((e: any) => ({ employeeId: e.employeeId, name: e.nameTitleCase, email: e.emailString }))
    });

    useEffect(() => changeTitle(t("Devices")), [i18n.language]);

    const { mutateAsync: create } = useCreateDevice({
        onSuccess: () => refetchOptions()
    });
    const { mutateAsync: updateAsyc, mutate: update } = useUpdateDevice({
        onSuccess: () => refetchOptions()
    });
    const remove = useDeleteDevice();

    const confirmRemoveTag = (data: Device) => {
        if (!window.confirm(t("Continue?") ?? undefined)) return;
        update({ ...data, tagType: null });
    };

    const getStatusChipAvatar = (value: DeviceStatus) => {
        if (value === DeviceStatus.Active)
            return <Done />;
        else
            return <Close />;
    };

    const getStatusChipColor = (value: DeviceStatus) => {
        if (value === DeviceStatus.Active)
            return "success";
        else
            return "error";
    };

    const renderTag = useCallback((type: string, row: Device) => {
        switch (type) {
            case "QR": return <QrCode sx={{ cursor: 'pointer' }} onClick={e => setQrMenu({ type: "QR", device: row, mouseX: e.clientX, mouseY: e.clientY })} />;
            case "NFC": return <Nfc />;
            case "None": return <Block />;
            default: return null;
        }
    }, []);

    const handleDeviceDowntimesClick = useCallback(() => {
        if (deviceDowntimes === "off")
            return setDeviceDowntimes("selection");

        if (deviceDowntimes === "selection") {
            if (rowSelectionModel.length)
                return setDeviceDowntimes("on");
            else
                return setDeviceDowntimes("off");
        }
    }, [deviceDowntimes, rowSelectionModel]);

    const deviceDowntimesLabel = useMemo(() => {
        if (deviceDowntimes === "off")
            return "MassShutdown";

        if (deviceDowntimes === "selection") {
            if (rowSelectionModel.length)
                return "SetDateForSelected";
            else
                return "ExitSelection"
        }

        return "";
    }, [deviceDowntimes, rowSelectionModel]);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => {
                const isInEditMode: boolean = apiRef.current.getRowMode(params.id) === 'edit';

                if (isInEditMode) {
                    return [<GridDeleteCellItem id={params.id} remove={remove} />];
                } else {
                    const menuList = [
                        <GridActionsCellItem icon={<History />} label={t("History")} onClick={() => setTrailId(params.id)} showInMenu />,
                        <GridDeleteCellItem id={params.id} remove={remove} showInMenu />,
                        <GridActionsCellItem icon={<DateRange />} label={t("Downtimes")} onClick={() => setDowntimeCalendarDeviceId(params.id)} showInMenu />,
                    ];

                    if (!isMobile && !params.row.tagType)
                        menuList.push(<GridActionsCellItem icon={<QrCode />} label="QR tag" onClick={() => setTag({ device: params.row, type: "QR" })} showInMenu />);

                    if (isAndroid && !params.row.tagType)
                        menuList.push(<GridActionsCellItem icon={<Nfc />} label="NFC tag" onClick={() => setTag({ device: params.row, type: "NFC" })} showInMenu />);

                    if (!params.row.tagType)
                        menuList.push(<GridActionsCellItem icon={<Block />} label="None tag" onClick={() => update({ ...params.row, tagType: 'None' })} showInMenu />);

                    if (params.row.tagType)
                        menuList.push(<GridActionsCellItem icon={<Delete />} label={`${t("Delete")} tag`} onClick={() => confirmRemoveTag(params.row)} showInMenu />);

                    return menuList;
                }
            },
        },
        { field: 'name', headerName: t("Name"), width: 270, editable: true, preProcessEditCellProps: editInputCellValidation.hasLength, renderHeader: EditableHeader, },
        {
            field: 'category', headerName: t("Category"), width: 330, editable: true, valueGetter: ({ value }) => value && value.name, renderHeader: EditableHeader,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.categories.map(c => c.name)} />, preProcessEditCellProps: editInputCellValidation.hasLength
        },
        { field: 'manufacturer', headerName: t("Manufacturer"), width: 150, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.manufacturers} freeSolo />, renderHeader: EditableHeader, },
        { field: 'partNumber', headerName: "PN", width: 170, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.partNumbers} freeSolo />, renderHeader: EditableHeader, },
        { field: 'serialNumber', headerName: "SN", width: 170, editable: true, renderHeader: EditableHeader, },
        { field: 'location', headerName: t("Location"), width: 190, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.locations} freeSolo />, renderHeader: EditableHeader, },
        { field: 'tagType', headerName: "Tag", width: 70, type: 'singleSelect', valueOptions: ['QR', 'NFC', 'None'], editable: false, renderCell: ({ value, row }) => renderTag(value, row), align: 'center', headerAlign: 'center' },
        {
            field: 'status', headerName: "Status", width: 150, editable: true, type: 'singleSelect', valueOptions: Object.values(DeviceStatus), renderHeader: EditableHeader,
            renderCell: ({ value }) => <Chip avatar={getStatusChipAvatar(value)} label={value} variant="outlined" color={getStatusChipColor(value)} />
        },
        {
            field: 'personInCharges', headerName: "PIC (email)", width: 700, editable: true, valueFormatter: ({ value }) => value?.map((p: DevicePersonInCharge) => p.name), renderHeader: EditableHeader,
            renderCell: params => <ChipInputCell params={{ ...params, value: params.value?.map((v: any) => `${v.name} (${v.employeeId})`) }} />,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={employees} getOptionLabel={o => `${o.name} (${o.employeeId})`} multiple />
        },
        { field: 'remark', headerName: t("Remark"), width: 350, editable: true, renderHeader: EditableHeader, },
    ] as GridColDef[], [options, employees, i18n.language]);

    const processRowUpdate = async (row: GridRowModel) => {
        const newDevice: Device = row as Device;

        const foundCategory = options.categories.find(c => c.name === row.category);
        if (!foundCategory)
            throw new Error("Category not found");

        newDevice.category = foundCategory;
        newDevice.personInCharges = newDevice.personInCharges?.map(p => ({ ...p, id: p.id ?? newGuid() }));

        if (newDevice.isNew)
            await create(newDevice);
        else
            await updateAsyc(newDevice);

        return { ...newDevice, isNew: false };
    };

    return (
        <React.Fragment>
            <TableContainer>
                <DataGridPremium rows={devices} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} apiRef={apiRef} editMode="row" processRowUpdate={processRowUpdate}
                    slotProps={{
                        toolbar: {
                            viewKey: "devices", newRowDefaultValues: { status: DeviceStatus.Active, }, items:
                                [<Button size="small" onClick={handleDeviceDowntimesClick} startIcon={<DateRange />}>{t(deviceDowntimesLabel)}</Button>]
                        } as GridToolbarProps
                    }}
                    checkboxSelection={deviceDowntimes === "selection"} disableRowSelectionOnClick onRowSelectionModelChange={setRowSelectionModel} rowSelectionModel={rowSelectionModel}
                />
            </TableContainer>

            <UpdateTag tag={tag} setTag={setTag} refetch={refetch} />
            <DowntimeCalendarDialog deviceId={downtimeCalendarDeviceId} setDeviceId={setDowntimeCalendarDeviceId} />
            <DeviceQrCode tagMenu={qrMenu} setTagMenu={setQrMenu} />
            <DeviceTrail primaryKey={trailId} setPrimaryKey={setTrailId} />
            <MassDowntimeCalendarDialog deviceDowntimes={deviceDowntimes} setDeviceDowntimes={setDeviceDowntimes} rowSelectionModel={rowSelectionModel} setRowSelectionModel={setRowSelectionModel} />
        </React.Fragment>
    );
}