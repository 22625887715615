import { Autocomplete, Box, Button, Menu, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { trimEnd } from "lodash";
import { useCallback, useContext, useState } from "react";
import { fetchGet, LayoutContext } from "wcz-layout";
import { DepartmentContext } from "../../contexts/DepartmentContext";
import Device from "../../models/Device";
import { pmtcUrl } from "../../utils/BaseUrl";

export interface SelectDeviceMenuModel {
    mouseX: number,
    mouseY: number
}

interface SelectDeviceMenuProps {
    menu: SelectDeviceMenuModel | null,
    setMenu: (menu: SelectDeviceMenuModel | null) => void,
    setDeviceId: (deviceId: string) => void
}

export default function SelectDeviceMenu(props: SelectDeviceMenuProps) {
    const { menu, setMenu, setDeviceId } = props;
    const [device, setDevice] = useState<Device | null>(null);
    const { t } = useContext(LayoutContext);
    const open = Boolean(menu);
    const { department } = useContext(DepartmentContext);

    const { data: devices = [] } = useQuery<Device[]>(["devices"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/device${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: open
    });

    const confirm = useCallback(() => {
        if (device)
            setDeviceId(device.id);

        handleMenuClose();
    }, [device]);

    const handleMenuClose = useCallback(() => {
        setMenu(null);
        setDevice(null);
    }, []);

    return (
        <Box>
            <Menu open={open} onClose={handleMenuClose} anchorReference="anchorPosition" variant="menu"
                anchorPosition={menu !== null ? { top: menu.mouseY, left: menu.mouseX } : undefined}>
                <Box sx={{ width: 340, px: 2 }} role="presentation">
                    <Typography variant="h6">{t("SelectDevice")}</Typography>

                    <Autocomplete
                        options={devices}
                        getOptionLabel={o => o.name}
                        value={device}
                        autoHighlight
                        onChange={(e, value) => setDevice(value)}
                        renderInput={(params) => <TextField {...params} label={t("Device")} fullWidth variant="standard" margin="normal" required autoFocus />}
                    />

                    <Button variant="contained" onClick={confirm} sx={{ float: "right", mb: 1 }}>{t("AddDefect")}</Button>
                </Box>
            </Menu>
        </Box>
    );
}