import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { fetchGet, fetchPut, LayoutContext } from 'wcz-layout';
import { Defect } from '../../models/Defect';
import { pmtcUrl } from '../../utils/BaseUrl';
import DefectDialog from './common/DefectDialog';

interface EditDefectProps {
    id: string,
    setId: (id: string) => void,
    refetch: () => void
}

export default function EditDefect(props: EditDefectProps) {
    const { id, setId, refetch } = props;
    const [defect, setDefect] = useState<Defect>({} as Defect);
    const { snackbar, t } = useContext(LayoutContext);

    useQuery<Defect>(["defect", id], ({ signal }) => fetchGet(pmtcUrl + "/v1/defect/" + id, signal), {
        enabled: !!id,
        onSuccess: data => setDefect(data)
    });

    const { mutate: update } = useMutation(() => fetchPut(pmtcUrl + "/v1/defect/" + id, defect), {
        onSuccess: () => {
            snackbar({ message: t("Updated"), severity: "success" });
            refetch();
            onClose();
        }
    });

    const onClose = () => {
        setId("");
        setDefect({} as Defect);
    };

    return <DefectDialog open={!!id} onClose={onClose} defect={defect} setDefect={setDefect} onSubmit={update} title={t("EditDefect")} />;
}