import { Box, Card, CardActionArea, CardContent, Grid, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

interface StyledCardProps {
    onClick: () => void,
    title: string,
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info' | 'default',
    children?: React.ReactNode,
    number?: number,
    info?: string,
    sx?: SxProps<Theme>
}

export default function StyledCard(props: StyledCardProps) {
    const { onClick, title, color, children, number, info, sx } = props;

    return (
        <Grid item lg={4} sm={6} xs={12} sx={sx}>
            <Card>
                {color && <Box sx={{ height: 20, backgroundColor: `${color}.main` }}></Box>}
                <CardActionArea onClick={onClick}>
                    <CardContent>
                        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography color="textSecondary" gutterBottom variant="overline" >{title}</Typography>
                                <Typography color="textPrimary" variant="h4" >{number}</Typography>
                            </Grid>
                            <Grid item>
                                {children}
                            </Grid>
                        </Grid>
                        <Box sx={{ pt: 2, display: 'flex', alignItems: 'center' }} >
                            <Typography color="textSecondary" variant="caption">{info}</Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}