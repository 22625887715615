import { Error, Home, PowerSettingsNew } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Fab, Paper, styled } from "@mui/material";
import { useContext } from "react";
import { LayoutContext } from "wcz-layout";

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});

interface BottomPanelProps {
    navigateHome: () => void;
    allTasksAnswered: boolean;
    deviceId?: string;
    handleOnAddDefect: () => void;
    handleOnAddShutdown: () => void;
}

export const BottomPanel: React.FC<BottomPanelProps> = ({ navigateHome, allTasksAnswered, deviceId, handleOnAddDefect, handleOnAddShutdown }) => {
    const { t } = useContext(LayoutContext);

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation showLabels>
                <BottomNavigationAction
                    label={t("CreateShutdown")}
                    icon={<PowerSettingsNew color={deviceId ? undefined : "disabled"} />}
                    sx={{ mr: 2 }}
                    onClick={handleOnAddShutdown}
                    disabled={!deviceId} />

                {allTasksAnswered &&
                    <StyledFab color="primary" onClick={navigateHome}>
                        <Home />
                    </StyledFab>
                }

                <BottomNavigationAction
                    label={t("AddDefect")}
                    icon={<Error color={deviceId ? undefined : "disabled"} />}
                    sx={{ ml: 2 }}
                    onClick={handleOnAddDefect}
                    disabled={!deviceId} />
            </BottomNavigation>
        </Paper>
    );
};