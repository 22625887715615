import { Box, Typography } from "@mui/material";
import { Nfc } from "@mui/icons-material";
import { useContext } from "react";
import { LayoutContext } from "wcz-layout";

export default function NfcTypography() {
    const { t } = useContext(LayoutContext);

    return (
        <Box sx={{ alignItems: 'center', flexWrap: 'wrap', mt: 2, textAlign: 'center' }}>
            <Nfc fontSize="large" sx={{ mr: 1 }} />
            <Typography>{t("AttachNfcTag")}</Typography>
        </Box>
    );
}