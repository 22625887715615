import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import ShutdownTemplate from "../../models/ShutdownTemplate";
import { pmtcUrl } from "../../utils/BaseUrl";
import TrailTable from "../common/TrailTable";

interface ShutdownTemplateTrailProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void
}

export default function ShutdownTemplateTrail(props: ShutdownTemplateTrailProps) {
    const { primaryKey, setPrimaryKey } = props;

    const { data = {} as ShutdownTemplate } = useQuery<ShutdownTemplate>(["shutdownTemplate", primaryKey], ({ signal }) => fetchGet(`${pmtcUrl}/v1/shutdownTemplate/${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    return <TrailTable primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} title={data.device?.name} />;
}