import { Article, Devices, Done, Nfc, QrCode as QrCodeIcon } from "@mui/icons-material";
import { Autocomplete, Box, Button, CircularProgress, Container, Fab, Grid, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useQuery } from "@tanstack/react-query";
import { trimEnd } from "lodash";
import QrCode from 'qrcode.react';
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { fetchGet, isMobile, LayoutContext } from "wcz-layout";
import { DepartmentContext } from "../../contexts/DepartmentContext";
import Device from "../../models/Device";
import WrittenTag from "../../models/WrittenTag";
import { pmtcUrl } from "../../utils/BaseUrl";
import { isNfcAvailable, writeNfc } from "../../utils/Nfc";

export default function DefectAutomate() {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [device, setDevice] = useState<Device | null>(null);
    const [defectDescription, setDefectDescription] = useState<string>("");
    const { changeTitle, t, i18n, user, snackbar } = useContext(LayoutContext);
    const { department } = useContext(DepartmentContext);

    useEffect(() => changeTitle(t("DefectAutomate")), [i18n.language]);

    const { data: devices = [] } = useQuery<Device[]>(["devices"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/device${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!user.department
    });

    const handleNext = useCallback(() => setActiveStep(activeStep + 1), [activeStep]);

    const handleOnDeviceChange = useCallback((e: React.SyntheticEvent<Element, Event>, value: Device | null) => {
        setDevice(value);
        setActiveStep(1);
    }, []);

    const handleOnDefectDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setDefectDescription(e.target.value), []);

    useEffect(() => {
        if (activeStep === 2 && isNfcAvailable && device?.id)
            writeNfc({
                value: JSON.stringify({ id: device.id, defectDescription } as WrittenTag),
                onSuccess: () => snackbar({ message: t("Created"), severity: "success" }),
                onError: message => snackbar({ message, severity: "error" }),
            });
    }, [activeStep, isNfcAvailable, device]);

    const handleReset = useCallback(() => {
        setDevice(null);
        setDefectDescription("");
        setActiveStep(0);
    }, []);

    const handleBack = useCallback(() => {
        setDefectDescription("");
        setActiveStep(1);
    }, []);

    return (
        <Container>
            <Grid container>
                <Grid item xs={12} sx={{ mt: 3 }}>
                    <Stepper alternativeLabel activeStep={activeStep}>
                        <Step sx={{ cursor: 'pointer' }} onClick={handleReset}>
                            <StepLabel icon={activeStep <= 0 ? <Devices /> : <Done color="success" />}>{t("SelectDevice")}</StepLabel>
                        </Step>
                        <Step sx={{ cursor: 'pointer' }} onClick={handleBack}>
                            <StepLabel icon={activeStep <= 1 ? <Article /> : <Done color="success" />}>{t("FillForm")}</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel icon={activeStep <= 2 ? <QrCodeIcon /> : <Done color="success" />}>{t("GenerateTag")}</StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="xs" sx={{ mt: 5 }}>
                        {activeStep === 0 &&
                            <Autocomplete
                                value={device}
                                options={devices}
                                getOptionLabel={option => option.name}
                                autoHighlight
                                onChange={handleOnDeviceChange}
                                fullWidth
                                renderInput={(params) => <TextField {...params} fullWidth label={t("Device")} autoFocus />}
                            />
                        }
                        {activeStep === 1 &&
                            <Fragment>
                                <TextField label={t("DefectDescription")} fullWidth multiline autoFocus value={defectDescription} onChange={handleOnDefectDescriptionChange} />
                                <Button variant="contained" sx={{ mt: 2, float: 'right' }} disabled={!defectDescription.length} onClick={handleNext}>{t("GenerateTag")}</Button>
                            </Fragment>
                        }

                        {activeStep === 2 && !isNfcAvailable &&
                            <Box>
                                <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>{t("SaveAndPrintTheQrCode")}</Typography>
                                <QrCode value={JSON.stringify({ id: device!.id, defectDescription: defectDescription } as WrittenTag)} size={396} />
                            </Box>
                        }

                        {activeStep === 2 && isNfcAvailable &&
                            <Box sx={{ display: "flex", height: 300 }}>
                                <Box sx={{ m: 'auto', position: 'relative' }}>
                                    <Tooltip open title={t("AttachNfcTag")} arrow sx={{ position: 'relative' }}>
                                        <Fab color="primary">
                                            <Nfc />
                                        </Fab>
                                    </Tooltip>
                                    <CircularProgress size={68} sx={{ color: green[500], position: 'absolute', top: -6, left: -6, zIndex: 1, }} />
                                </Box>
                            </Box>
                        }

                        {activeStep === 2 && isMobile && !isNfcAvailable && <Typography>{t("NfcNotFound")}</Typography>}
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}