import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { fetchGet, fetchPost, LayoutContext, newGuid } from 'wcz-layout';
import { Defect } from '../../models/Defect';
import Device from '../../models/Device';
import { pmtcUrl } from '../../utils/BaseUrl';
import DefectDialog from './common/DefectDialog';

interface AddDefectProps {
    id: string,
    setId: (id: string) => void,
    refetch: () => void
}

export default function AddDefect(props: AddDefectProps) {
    const { id, setId, refetch } = props;
    const [defect, setDefect] = useState<Defect>({} as Defect);
    const { t, snackbar } = useContext(LayoutContext);

    useQuery<Device>(["device", id], ({ signal }) => fetchGet(`${pmtcUrl}/v1/device/${id}`, signal), {
        enabled: !!id,
        onSuccess: data => setDefect({ ...defect, id: newGuid(), description: "", device: data })
    });

    const { mutate } = useMutation((req: Defect) => fetchPost(pmtcUrl + "/v1/defect", req), {
        onSuccess: () => {
            refetch();
            onClose();
        },
        onError: (message: string) => {
            refetch();
            snackbar({ message, severity: "error" });
        }
    });

    const createDefect = () => {
        mutate(defect);
    };

    const onClose = () => {
        setId("");
        setDefect({} as Defect);
    };

    return <DefectDialog open={!!id} onClose={onClose} defect={defect} setDefect={setDefect} onSubmit={createDefect} title={t("AddDefect")} />;
}