import { CheckBox, CheckBoxOutlineBlank, Close } from "@mui/icons-material";
import { Box, CardHeader, Checkbox, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { FileViewer } from "wcz-file";
import { fetchGet } from "wcz-layout";
import { TaskResult } from "../../models/enums/TaskResult";
import Maintenance from "../../models/Maintenance";
import { pmtcUrl } from "../../utils/BaseUrl";

interface DetailPanelProps {
    params: GridRowParams
}

export default function DetailPanel(props: DetailPanelProps) {
    const { params } = props;

    const { data: maintenance = {} as Maintenance } = useQuery<Maintenance>(["maintenance", params.id], ({ signal }) => fetchGet(pmtcUrl + "/v1/maintenance/" + params.id, signal), {
        enabled: !!params.id
    });

    return (
        <Box sx={{ overflow: 'auto', height: 200 }}>
            <List dense>
                {maintenance.tasks?.map(task => (
                    <React.Fragment key={task.id}>
                        <ListItem>
                            <ListItemAvatar>
                                {task.result === TaskResult.NA && <CheckBoxOutlineBlank />}
                                {task.result === TaskResult.OK && <CheckBox color="success" />}
                                {task.result === TaskResult.NG && <Close color="error" />}
                            </ListItemAvatar>
                            <ListItemText primary={task.text} />
                            <FileViewer subId={task.id} disableActions />
                        </ListItem>

                        {task.defects?.map(defect =>
                            <Box key={defect.id} sx={{ px: 0.2, ml: 3, borderLeft: "solid", borderLeftColor: 'red' }}>
                                <CardHeader title={defect.description} subheader={defect.repairDescription} sx={{ p: 0 }}
                                    avatar={<Checkbox checked={defect.isFixed} color={defect.isFixed ? "success" : "default"} disabled />}
                                />
                                <FileViewer subId={task.id} />
                            </Box>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
}