import { GridRowId } from "@mui/x-data-grid-premium";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import Category from "../models/Category";
import { pmtcUrl } from "../utils/BaseUrl";

const queryKey: string = "Category";

export function useGetCategories<TQueryFnData = Category[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${pmtcUrl}/v1/${queryKey}`, signal), options);
}

export function useGetCategory<TQueryFnData = Category, TError = string, TData = TQueryFnData>(id: GridRowId, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>) {
    return useQuery([queryKey, id], ({ signal }) => fetchGet(`${pmtcUrl}/v1/${queryKey}/${id}`, signal), options);
}

interface UseCreateOptions {
    onSuccess?: (data: Category) => void,
    onError?: (message: string) => void,
}

export function useCreateCategory(options?: UseCreateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Category) => fetchPost(`${pmtcUrl}/v1/${queryKey}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Category[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], () => [model, ...previousData]);

                return { previousData };
            }
        },
        onError: (err: string, model, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: (model, variables, context) => {
            if (context)
                queryClient.setQueryData([queryKey], () => [model, ...context.previousData]);
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}

interface UseUpdateOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateCategory(options?: UseUpdateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Category) => fetchPut(`${pmtcUrl}/v1/${queryKey}/${model.id}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Category[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], previousData.map(prev => prev.id === model.id ? model : prev));

                return { previousData, model };
            }
        },
        onError: (err: string, model, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteCategory(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((id: GridRowId) => fetchDelete(`${pmtcUrl}/v1/${queryKey}/${id}`), {
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Category[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], previousData.filter(prev => prev.id !== id));

                return { previousData };
            }
        },
        onError: (err: string, id, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};