import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import Template from "../../models/Template";
import { pmtcUrl } from "../../utils/BaseUrl";
import TrailTable from "../common/TrailTable";

interface TemplateTrailProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void
}

export default function TemplateTrail(props: TemplateTrailProps) {
    const { primaryKey, setPrimaryKey } = props;

    const { data = {} as Template } = useQuery<Template>(["template", primaryKey], ({ signal }) => fetchGet(`${pmtcUrl}/v1/template/${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    return <TrailTable primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} title={data.category?.name ?? ""} />;
}