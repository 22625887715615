import { Close } from "@mui/icons-material";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, TextField } from "@mui/material";
import moment from "moment";
import { Fragment, useCallback, useContext } from "react";
import { FileViewer, MetaInfo, UploadFile } from 'wcz-file';
import { LayoutContext } from "wcz-layout";
import { Defect } from "../../../models/Defect";
import LoadingButton from "../../common/LoadingButton";

interface DefectDialogProps {
    open: boolean,
    onClose: () => void,
    defect: Defect,
    setDefect: (defect: Defect) => void,
    onSubmit: () => void,
    title: string
}

export default function DefectDialog(props: DefectDialogProps) {
    const { defect, setDefect, open, onClose, title, onSubmit } = props;
    const { t, user } = useContext(LayoutContext);

    const handleOnIsFixedChange = (checked: boolean) => {
        const updatedDefect: Defect = { ...defect };
        updatedDefect.isFixed = checked;

        if (!checked) {
            updatedDefect.repairDescription = null;
            updatedDefect.finishedBy = undefined;
            updatedDefect.finishedOn = undefined;
        } else {
            updatedDefect.finishedBy = `${user?.name} (${user?.id})`;
            updatedDefect.finishedOn = moment().format("YYYY-MM-DDTHH:mm");
        }

        setDefect(updatedDefect);
    };

    const handleOnSubmit = () => onSubmit();

    const handleOnFileUpload = useCallback(() => setDefect({ ...defect, hasFile: true }), [defect]);
    const handleOnFileDelete = useCallback((metas: MetaInfo[]) => setDefect({ ...defect, hasFile: !!metas.length }), [defect]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <Grid container justifyContent="space-between" sx={{ height: 50 }}>
                <Grid item>
                    <DialogTitle>{title}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                <TextField variant="standard" margin="dense" fullWidth label={t("Description")} value={defect.description ?? ""} onChange={e => setDefect({ ...defect, description: e.target.value })} required />

                <FormGroup>
                    <FormControlLabel control={<Checkbox onChange={e => handleOnIsFixedChange(e.target.checked)} />} label={t("IsFixed")} checked={defect.isFixed ?? false} />
                </FormGroup>

                {defect.isFixed &&
                    <TextField variant="standard" margin="dense" fullWidth label={t("RepairDescription")} value={defect.repairDescription ?? ""} onChange={e => setDefect({ ...defect, repairDescription: e.target.value })} />
                }

                {defect.id &&
                    <Fragment>
                        <UploadFile subId={defect.id} sx={{ mt: 2 }} onSuccess={handleOnFileUpload} />
                        <FileViewer subId={defect.id} sx={{ mt: 2 }} onDelete={handleOnFileDelete} />
                    </Fragment>
                }
            </DialogContent>

            <DialogActions>
                <LoadingButton onClick={handleOnSubmit} disabled={!defect.description || (defect.isFixed && !defect.repairDescription)}>{t("Save")}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}