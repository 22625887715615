import { CheckBox, CheckBoxOutlineBlank, Close, Done } from "@mui/icons-material";
import { Autocomplete, Button, Card, CardActionArea, CardActions, CardHeader, Container, Fab, Grid, List, TextField, Typography } from "@mui/material";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LayoutContext, newGuid } from "wcz-layout";
import { TaskResult } from "../models/enums/TaskResult";
import { Shutdown } from "../models/Shutdown";
import ShutdownTask from "../models/ShutdownTask";
import ShutdownTemplate from "../models/ShutdownTemplate";
import { useCreateShutdown } from "../services/ShutdownService";
import { useGetShutdownTemplate, useGetShutdownTemplates } from "../services/ShutdownTemplateService";

export default function CreateShutdown() {
    const { deviceId } = useParams();
    const { changeTitle, snackbar, t, i18n } = useContext(LayoutContext);
    const [shutdown, setShutdown] = useState<Shutdown>({} as Shutdown);
    const navigate = useNavigate();

    useEffect(() => changeTitle(t("CreateShutdown")), [i18n.language]);

    const { data: templates = [] } = useGetShutdownTemplates({
        onSuccess: data => {
            if (deviceId) {
                const template = data.find(t => t.device.id === deviceId);
                if (template)
                    setShutdown({ ...shutdown, template: { ...shutdown.template, id: template.id } });
            }
        }
    });

    useGetShutdownTemplate(shutdown.template?.id, {
        enabled: !!shutdown.template?.id,
        onSuccess: (data) => setShutdown({ ...shutdown, template: data, tasks: data.tasks.map(t => ({ id: newGuid(), position: t.position!, result: TaskResult.NA, text: t.text })) })
    });

    const handleOnTemplateChange = useCallback((event: React.SyntheticEvent, value: ShutdownTemplate) =>
        setShutdown({
            ...shutdown,
            template: value
        }), [shutdown]);

    const handleOnEndDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setShutdown({
            ...shutdown,
            endDate: e.target.value
        }), [shutdown]);

    const handleOnRemarkChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setShutdown({
            ...shutdown,
            remark: e.target.value
        }), [shutdown]);

    const updateTaskResult = useCallback((task: ShutdownTask, result: TaskResult) => () => {
        setShutdown({ ...shutdown, tasks: shutdown.tasks.map(t => t.id === task.id ? { ...t, result: result } : t) });
    }, [shutdown]);

    const { mutate: create } = useCreateShutdown({
        onSuccess: () => {
            snackbar({ message: t("ShutdownCreated") });
            navigate(`/shutdowns`);
        }
    });

    const handleCreateShutdown = useCallback(() => create(shutdown), [shutdown]);

    const allTasksAnswered = useMemo(() => shutdown.tasks?.every(t => t.result !== TaskResult.NA), [shutdown]);

    return (
        <Fragment>
            <Container sx={{ my: 4 }}>
                <Grid container spacing={2} sx={{ px: 1, pb: 5 }}>
                    <Grid item xs={12} md={8}>
                        <Autocomplete
                            options={templates}
                            value={shutdown.template ?? null}
                            getOptionLabel={(option) => option.device.name}
                            autoHighlight
                            disableClearable
                            onChange={handleOnTemplateChange}
                            renderInput={(params) => <TextField {...params} fullWidth label={t("Template")} variant="outlined" required />}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField label={t("EndDate")} type="datetime-local" InputLabelProps={{ shrink: true }} value={shutdown.endDate} onChange={handleOnEndDateChange} fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField label={t("Remark")} size="small" multiline value={shutdown.remark} onChange={handleOnRemarkChange} fullWidth />
                    </Grid>
                </Grid>

                <List sx={{ overflow: 'auto', height: { xs: "calc(100vh - 76px)", sm: "calc(100vh - 84px)" }, padding: 0, pb: 20 }}>
                    {shutdown.tasks?.map(task =>
                        <Fragment key={task.id}>
                            <Card variant="outlined" sx={{ m: 1 }}>
                                <CardActionArea onClick={updateTaskResult(task, task.result === TaskResult.OK ? TaskResult.NA : TaskResult.OK)}>
                                    <CardHeader title={<Typography whiteSpace="break-spaces">{task.text}</Typography>} avatar={
                                        <Fragment>
                                            {task.result === TaskResult.NA && <CheckBoxOutlineBlank />}
                                            {task.result === TaskResult.OK && <CheckBox color="success" />}
                                            {task.result === TaskResult.NG && <Close color="error" />}
                                        </Fragment>
                                    } />
                                </CardActionArea>

                                <CardActions>
                                    <Button color="error" onClick={updateTaskResult(task, TaskResult.NG)}>{t("NotGood")}</Button>
                                </CardActions>
                            </Card>
                        </Fragment>
                    )}
                </List>
            </Container>

            <Grid container sx={{ position: 'fixed', bottom: 30 }}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    {allTasksAnswered &&
                        <Fab color="primary" onClick={handleCreateShutdown}>
                            <Done />
                        </Fab>
                    }
                </Grid>
            </Grid>
        </Fragment>
    );
}