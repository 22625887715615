import { useMutation, useQuery } from "@tanstack/react-query";
import { trimEnd } from "lodash";
import { useContext, useState } from "react";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import { DepartmentContext } from "../../contexts/DepartmentContext";
import Category from "../../models/Category";
import Template from "../../models/Template";
import { pmtcUrl } from "../../utils/BaseUrl";
import TemplateDialog from "./common/TemplateDialog";

interface EditTemplateProps {
    id: string,
    setId: (id: string) => void,
    refetch: () => void,
}

export default function EditTemplate(props: EditTemplateProps) {
    const { id, setId, refetch } = props;
    const [template, setTemplate] = useState({} as Template);
    const { snackbar, t } = useContext(LayoutContext);
    const { department } = useContext(DepartmentContext);

    useQuery<Template>(["template", id], ({ signal }) => fetchGet(pmtcUrl + "/v1/template/" + id, signal), {
        enabled: !!id,
        onSuccess: (data) => setTemplate(data)
    });

    const { data: categories = [] } = useQuery<Category[]>(["categories"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/category${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!id,
    });

    const { mutate: update } = useMutation((data: Template) => fetchPut(pmtcUrl + "/v1/template/" + template.id, data), {
        onSuccess: () => {
            snackbar({ message: t("Updated"), severity: "success" });
            refetch();
            setId("");
            onClose();
        },
    });

    const onClose = () => {
        setTemplate({} as Template);
        setId("");
    };

    const onSubmit = () => {
        update({
            ...template,
            tasks: template.tasks.map((task, index) => ({ ...task, position: index }))
        });
    };

    return <TemplateDialog open={!!template.id} onClose={onClose} template={template} setTemplate={setTemplate} onSubmit={onSubmit} categories={categories} />;
}