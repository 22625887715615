import { Add, Assignment, Devices } from "@mui/icons-material";
import { DataGridPremium, GridColDef, GridLogicOperator, GridValidRowModel, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LayoutContext } from "wcz-layout";
import { EditableHeader, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import { DateTimeEditInputCell } from "../components/common/GridDateTimeInput";
import DetailPanel from "../components/shutdown/DetailPanel";
import { DevicesFilterMenu } from "../components/shutdown/DevicesFilterMenu";
import { Shutdown } from "../models/Shutdown";
import { useGetShutdowns, useUpdateShutdown } from "../services/ShutdownService";

export default function Shutdowns() {
    const { onlyActive } = useParams();
    const [devicesMenu, setDevicesMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const { changeTitle, user, t, i18n } = useContext(LayoutContext);
    const navigate = useNavigate();
    const apiRef = useGridApiRef();

    useEffect(() => changeTitle(t("Shutdowns")), [i18n.language]);

    const { data: shutdowns = [], isLoading } = useGetShutdowns({
        enabled: !!user.department
    });

    useEffect(() => {
        if (onlyActive)
            setFilterModel();
        else
            clearFilterModel();

    }, [onlyActive]);


    const setFilterModel = () => {
        apiRef.current.setFilterModel({
            items: [
                { field: 'endDate', operator: "isEmpty", value: undefined },
                { field: 'endDate', operator: "after", value: moment().formatDate() },
            ],
            logicOperator: GridLogicOperator.Or
        });
    };

    const clearFilterModel = () => apiRef.current.setFilterModel({ items: [] });

    const { mutateAsync: updateAsync } = useUpdateShutdown();

    const columns: GridColDef[] = useMemo(() => [
        { field: 'template', headerName: t("Device"), width: 250, valueGetter: ({ value }) => value?.device.name },
        { field: 'startDate', headerName: t("Start"), width: 250, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime(), },
        {
            field: 'endDate', headerName: t("End"), width: 250, type: 'date', editable: true, valueFormatter: ({ value }) => moment(value).formatDateTime(),
            renderEditCell: params => <DateTimeEditInputCell {...params} />, renderHeader: EditableHeader,
        },
        { field: 'remark', headerName: t("Remark"), width: 450, editable: true, renderHeader: EditableHeader, },
        { field: 'createdBy', headerName: t("CreatedBy"), width: 250 },
    ] as GridColDef[], [i18n.language]);

    const navigateToShutdownCreate = useCallback(() => navigate("/shutdown/create"), []);

    const navigateToTemplates = useCallback(() => navigate(`/shutdown/templates`), []);

    const processRowUpdate = useCallback(async (row: GridValidRowModel): Promise<GridValidRowModel> => {
        row.endDate = moment(row.endDate).isValid() ? row.endDate : null;
        updateAsync(row as Shutdown);
        return row;
    }, []);

    const openDeviceFilter = useCallback((e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => setDevicesMenu({ mouseX: e.clientX, mouseY: e.clientY }), []);

    const handleOnDeviceSelect = useCallback((device: string) => apiRef.current.setFilterModel({ items: [{ field: "template", operator: "equals", value: device }] }), [apiRef.current]);

    return (
        <React.Fragment>
            <TableContainer>
                <DataGridPremium rows={shutdowns} columns={columns} apiRef={apiRef} slots={{ toolbar: GridToolbar }} loading={isLoading} editMode="row" processRowUpdate={processRowUpdate}
                    getDetailPanelContent={params => <DetailPanel params={params} />} getDetailPanelHeight={() => 200}
                    slotProps={{
                        toolbar: {
                            items: [{ title: t("DataGrid.AddRecord"), icon: <Add />, onClick: navigateToShutdownCreate },
                            { title: t("FilterDevices"), icon: <Devices />, onClick: openDeviceFilter },
                            { title: t("Templates"), icon: <Assignment />, onClick: navigateToTemplates }],
                            hideAddRecord: true,
                            viewKey: "shutdowns"
                        } as GridToolbarProps
                    }}
                />
            </TableContainer>

            <DevicesFilterMenu contextMenu={devicesMenu} setContextMenu={setDevicesMenu} devices={shutdowns.flatMap(d => d.template.device.name).sort((a: string, b: string) => a.localeCompare(b))} onSelect={handleOnDeviceSelect} />
        </React.Fragment>
    );
}