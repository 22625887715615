import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LayoutContext, newGuid } from 'wcz-layout';
import ShutdownTemplate from '../../models/ShutdownTemplate';
import { useCreateShutdownTemplate } from '../../services/ShutdownTemplateService';
import { Button } from '../common/Button';
import { CustomDialog } from '../common/CustomDialog';
import { ShutdownTemplateForm } from './form/ShutdownTemplateForm';

interface AddShutdownTemplateProps {
    open: boolean;
    onClose: () => void;
}

export const AddShutdownTemplate: React.FC<AddShutdownTemplateProps> = ({ open, onClose }) => {
    const [template, setTemplate] = useState<ShutdownTemplate>({} as ShutdownTemplate);
    const { t } = useContext(LayoutContext);

    useEffect(() => {
        if (open && !template.id)
            init();
    }, [open]);

    const init = useCallback(() => setTemplate({
        ...template,
        id: newGuid(),
        tasks: [{ id: newGuid(), text: "" }]
    }), []);

    const { mutate, isLoading: isMutating } = useCreateShutdownTemplate({
        onSuccess: () => {
            setTemplate({} as ShutdownTemplate);
            onClose();
        }
    });

    const handleSave = useCallback(() => {
        mutate({
            ...template,
            tasks: template.tasks.map((task, index) => ({ ...task, position: index }))
        });
    }, [template]);

    const canSave = useMemo(() => template.device && template.tasks.length > 0, [template]);

    return (
        <CustomDialog title={t('CreateTemplate')} open={open} onClose={onClose} color="primary" maxWidth="lg" actions={[
            <Button key="save" onClick={handleSave} disabled={isMutating || !canSave} variant="contained">{t('Save')}</Button>
        ]}>
            <ShutdownTemplateForm template={template} setTemplate={setTemplate} />
        </CustomDialog>
    );
};