import { Nfc } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, DialogContent, Fab, Tooltip, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useMutation } from "@tanstack/react-query";
import QrCode from 'qrcode.react';
import { Fragment, useCallback, useContext, useEffect } from "react";
import { fetchPut, LayoutContext } from "wcz-layout";
import Device from "../../models/Device";
import WrittenTag from "../../models/WrittenTag";
import { Tag } from "../../pages/Devices";
import { pmtcUrl } from "../../utils/BaseUrl";
import { writeNfc } from "../../utils/Nfc";

interface UpdateTagProps {
    tag: Tag,
    setTag: (tag: Tag) => void,
    refetch: () => void
}

export default function UpdateTag(props: UpdateTagProps) {
    const { tag, setTag, refetch } = props;
    const { snackbar, t } = useContext(LayoutContext);

    useEffect(() => {
        if (tag.type === "NFC")
            writeNfc({
                value: JSON.stringify({ id: tag.device.id } as WrittenTag),
                onSuccess: () => handleOnClose(),
                onError: (err) => { snackbar({ message: err, severity: "error" }); setTag({} as Tag); },
            });
    }, [tag]);

    const { mutate } = useMutation((req: Device) => fetchPut(pmtcUrl + "/v1/device/" + req.id, req), {
        onSuccess: () => {
            snackbar({ message: t("Updated"), severity: "success" });
            refetch();
        },
    });

    const handleOnClose = useCallback(() => {
        if (!tag.device.tagType)
            mutate({ ...tag.device, tagType: tag.type });

        setTag({} as Tag);
    }, [tag]);

    const nfc = (
        <Box sx={{ display: "flex", height: 300 }}>
            <Box sx={{ m: 'auto', position: 'relative' }}>
                <Tooltip open title={t("AttachNfcTag")} arrow sx={{ position: 'relative' }}>
                    <Fab color="primary">
                        <Nfc />
                    </Fab>
                </Tooltip>
                <CircularProgress size={68} sx={{ color: green[500], position: 'absolute', top: -6, left: -6, zIndex: 1, }} />
            </Box>
        </Box>
    );

    return (
        <Dialog open={!!tag.type} onClose={handleOnClose} fullWidth>
            {tag.type === "QR" &&
                <Fragment>
                    <Typography variant="h6" sx={{ textAlign: 'center', mt: 1 }}>{t("SaveAndPrintTheQrCode")}</Typography>
                    <DialogContent>
                        <QrCode value={JSON.stringify({ id: tag.device.id } as WrittenTag)} size={550} />
                    </DialogContent>
                </Fragment>
            }
            {tag.type === "NFC" && nfc}
        </Dialog>
    );
}