import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import { Defect } from "../../models/Defect";
import { pmtcUrl } from "../../utils/BaseUrl";
import TrailTable from "../common/TrailTable";

interface DefectTrailProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void
}

export default function DefectTrail(props: DefectTrailProps) {
    const { primaryKey, setPrimaryKey } = props;

    const { data = {} as Defect } = useQuery<Defect>(["defect", primaryKey], ({ signal }) => fetchGet(`${pmtcUrl}/v1/defect/${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    return <TrailTable primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} title={data.device?.name ?? ""} />;
}