import { CheckBox, DisabledByDefault } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Container, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FileViewer } from "wcz-file";
import { fetchGet, LayoutContext } from "wcz-layout";
import { Defect } from "../../models/Defect";
import { pmtcUrl } from "../../utils/BaseUrl";

export default function DefectDetail() {
    const { id } = useParams();
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    const { data: defect = {} as Defect } = useQuery<Defect>(["defect", id], ({ signal }) => fetchGet(`${pmtcUrl}/v1/defect/${id}`, signal));

    useEffect(() => changeTitle(t("Defect")), [i18n.language]);

    return (
        <Container sx={{ my: 2 }}>

            <Card>
                <CardHeader title={t("Defect")} />
                <CardContent>
                    <Typography><b>{t("Description")}:</b> {defect.description}</Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}><b>{t("IsFixed")}:</b> {defect.isFixed ? <CheckBox color="success" /> : <DisabledByDefault color="error" />}</Typography>
                    <Typography><b>{t("Departments")}:</b> {defect.device?.category?.departments.map(department => department.value).join(", ")}</Typography>
                    <Typography><b>{t("Category")}:</b> {defect.device?.category?.name}</Typography>
                    <Typography><b>{t("Device")}:</b> {defect.device?.name}</Typography>
                    {defect.device?.serialNumber && <Typography><b>SN:</b> {defect.device.serialNumber}</Typography>}
                    <Typography><b>{t("Created")}:</b> {moment(defect.createdOn).formatDateTime()}</Typography>
                    <Typography><b>{t("CreatedBy")}:</b> {defect.createdBy}</Typography>
                    {defect.finishedOn && <Typography><b>{t("FinishedDate")}:</b> {moment(defect.finishedOn).formatDateTime()}</Typography>}
                    {defect.finishedBy && <Typography><b>{t("FinishedBy")}:</b> {defect.finishedBy}</Typography>}
                    {defect.repairDescription && <Typography><b>{t("RepairDescription")}:</b> {defect.repairDescription}</Typography>}
                    <FileViewer subId={defect.id} sx={{ mt: 2 }} disableActions />
                </CardContent>
            </Card>
        </Container >
    );
}