import { TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import React, { useCallback } from "react";

export const DateTimeEditInputCell: React.FC<GridRenderEditCellParams> = ({ id, value, field, hasFocus }) => {
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLInputElement>(null);

    React.useLayoutEffect(() => {
        if (ref.current && hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    const handleValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    }, [apiRef.current, id, field]);

    return <TextField ref={ref} type="datetime-local" value={value} onChange={handleValueChange} fullWidth />;
};