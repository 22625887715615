import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { DateRange, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useContext, useState } from "react";
import { LayoutContext, fetchGet, fetchPut, isMobile, newGuid } from "wcz-layout";
import Device from "../../models/Device";
import { pmtcUrl } from "../../utils/BaseUrl";
import LoadingButton from "../common/LoadingButton";

interface MassDowntimeCalendarDialogProps {
    rowSelectionModel: GridRowSelectionModel,
    setRowSelectionModel: (rowSelectionModel: GridRowSelectionModel) => void,
    deviceDowntimes: "off" | "selection" | "on";
    setDeviceDowntimes: (deviceDowntimes: "off" | "selection" | "on") => void;
}

export default function MassDowntimeCalendarDialog(props: MassDowntimeCalendarDialogProps) {
    const { rowSelectionModel, setRowSelectionModel, deviceDowntimes, setDeviceDowntimes } = props;
    const [calendarValue, setCalendarValue] = useState<DateRange<Date>>([null, null]);
    const [errors, setErrors] = useState<string[]>([]);
    const { t, snackbar } = useContext(LayoutContext);

    const { mutateAsync: getDevice } = useMutation((deviceId: string) => fetchGet(`${pmtcUrl}/v1/device/${deviceId}`, undefined), {
        onSuccess: (data: Device) => {
            const startDate: string = moment(calendarValue[0]).formatDate();
            if (!startDate) return;

            const endDate: string = moment(calendarValue[1] ?? calendarValue[0]).formatDate();

            update({
                ...data,
                downtimes: [...data.downtimes, { startDate: startDate, endDate: endDate, id: newGuid() }]
            });
        },
        onError: (error: string) => {
            setErrors([...errors, error]);
        }
    });

    const { mutate: update } = useMutation((data: Device) => fetchPut(pmtcUrl + "/v1/device/" + data.id, data));

    const handleOnSave = () => {
        const deviceUpdatePromises = rowSelectionModel.map(deviceId => getDevice(String(deviceId)));
        Promise.all(deviceUpdatePromises).then(() => {
            snackbar({ message: t("SuccessfullyAddedToAllDevices") })
            onClose();
        });
    };

    const onClose = useCallback(() => {
        setDeviceDowntimes("off");
        setRowSelectionModel([]);
        setErrors([]);
    }, []);

    return (
        <Dialog open={deviceDowntimes === "on"} onClose={onClose} fullScreen={isMobile}>
            <Grid container justifyContent="space-between" sx={{ height: 50 }}>
                <Grid item>
                    <DialogTitle>{t("Downtimes")}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                <Typography variant="caption">{t("MaintenanceIsNotGeneratedOnPublicHolidays")}</Typography>

                <StaticDateRangePicker
                    displayStaticWrapperAs="desktop"
                    disableHighlightToday
                    calendars={1}
                    value={calendarValue}
                    onChange={(newValue) => { setCalendarValue(newValue); }}

                />

                <LoadingButton onClick={handleOnSave} variant="outlined" fullWidth>{t("Save")}</LoadingButton>

                {errors.map(err =>
                    <Typography key={err}>{err}</Typography>
                )}
            </DialogContent>
        </Dialog>
    );
}