import { Add, Create, History, PowerSettingsNew } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "wcz-layout";
import { GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import { AddShutdownTemplate } from "../components/shutdownTemplates/AddShutdownTemplate";
import { EditShutdownTemplate } from "../components/shutdownTemplates/EditShutdownTemplate";
import ShutdownTemplateTrail from "../components/shutdownTemplates/ShutdownTemplateTrail";
import { useDeleteShutdownTemplate, useGetShutdownTemplates } from "../services/ShutdownTemplateService";

export default function ShutdownTemplates() {
    const { changeTitle, user, t, i18n } = useContext(LayoutContext);
    const [addOpen, setAddOpen] = useState(false);
    const [editId, setEditId] = useState("");
    const [trailId, setTrailId] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => changeTitle(t("ShutdownTemplates")), [i18n.language]);

    const { data: templates = [], isLoading } = useGetShutdownTemplates({
        enabled: !!user.department
    });

    const remove = useDeleteShutdownTemplate();

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="edit" icon={<Create />} label={t("Edit")} onClick={() => setEditId(params.id)} showInMenu />,
                <GridDeleteCellItem key="remove" id={params.id} remove={remove} showInMenu />,
                <GridActionsCellItem key="history" icon={<History />} label={t("History")} onClick={() => setTrailId(params.id)} showInMenu />,
            ],
        },
        { field: 'device', headerName: t("Device"), width: 250, valueGetter: ({ value }) => value.name },
    ] as GridColDef[], [i18n.language]);

    const navigateToShutdowns = useCallback(() => navigate(`/shutdowns`), []);

    return (
        <React.Fragment>
            <TableContainer>
                <DataGridPremium rows={templates} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} onRowDoubleClick={({ id }) => setEditId(id as string)}
                    slotProps={{
                        toolbar: {
                            items: [{ title: t("DataGrid.AddRecord"), icon: <Add />, onClick: () => setAddOpen(true) },
                            { title: t("Shutdowns"), icon: <PowerSettingsNew />, onClick: navigateToShutdowns }],
                        } as GridToolbarProps
                    }} />
            </TableContainer>

            <AddShutdownTemplate open={addOpen} onClose={() => setAddOpen(false)} />
            <EditShutdownTemplate id={editId} setId={setEditId} />
            <ShutdownTemplateTrail primaryKey={trailId} setPrimaryKey={setTrailId} />
        </React.Fragment>
    );
}